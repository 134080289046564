<script lang="ts" setup>
  import { Company } from '@/domains/models/Company';
  import type { Ref } from 'vue';
  import {
    computed,
    defineEmits,
    defineProps,
    nextTick,
    ref,
    set as $set,
    watch,
    withDefaults,
  } from 'vue';
  import { RouterLink } from 'vue-router';
  import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
  import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
  import { useQuery, useQueryClient } from '@tanstack/vue-query';
  import contractsController from '@/managers/contracts/controller';
  import { useRoute, useRouter } from 'vue-router/composables';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { getCompanyContacts } from '@/api/company';
  import { Process } from '@/domains/models/Process';
  import PanelCarousel from '../PanelCarousel.vue';
  import ProcessDropdown from '@/components/Process/ProcessDropdown.vue';
  import PanelCompanyTop from '@/components/Panel/PanelCompany/PanelCompanyTop.vue';
  import PanelCompanyContacts from '@/components/Panel/PanelCompany/PanelCompanyContacts.vue';
  import PanelCompanyDetails from '@/components/Panel/PanelCompany/PanelCompanyDetails.vue';
  import PanelCompanyEmployees from '@/components/Panel/PanelCompany/PanelCompanyEmployees.vue';
  import PanelCompanyProcess from '@/components/Panel/PanelCompany/PanelCompanyProcess.vue';
  import PanelCompanyDocuments from '@/components/Panel/PanelCompany/PanelCompanyDocuments.vue';
  import { apiTypes } from '@/macros/contracts/types';
  import { getCustomJobs } from '@/api/custom-jobs';
  import { Opportunity } from '@/domains/models/Opportunity';
  import PanelCompanyOpportunities
    from '@/components/Panel/PanelCompany/PanelCompanyOpportunities.vue';
  import { useI18n } from '@/i18n/i18n';
  import PanelCompanyDescription from '@/components/Panel/PanelCompany/PanelCompanyDescription.vue';
  import { useElementBounding, useScroll } from '@vueuse/core';
  import { useProcessesApi } from '@/composables/api/useProcessesApi';
  import { useProcessesFilter } from '@/composables/useProcessesFilter';
  import ViewsTabs from '@/components/Views/ViewsTabs';

  const props = withDefaults(defineProps<{
    profile: Company
  }>(), {
    profile: () => ({}) as Company,
  });
  const emits = defineEmits<{
    (e: 'edit-company', value: boolean): void,
    (e: 'update-company', value: Company): void,
    (e: 'update-process', value: Process): void
  }>();

  const processesApi = useProcessesApi();

  const {
    statusFilterOptions,
    processStatusFilter,
  } = useProcessesFilter();

  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const companyId = computed(() => route.params.id);

  const viewTabs = [
    {
      key: 'profile-documents',
      icon: 'companies',
      label: t('generics.profile-document'),
      onClick: () => {
        activeViewTab.value = 'profile-documents';
        router.replace({
          query: {
            ...route.query,
            'view-tab': 'profile-documents',
          },
        });
      },
    },
    {
      key: 'opportunities',
      icon: 'briefcase',
      label: t('global.opportunities'),
      onClick: () => {
        activeViewTab.value = 'opportunities';
        router.replace({
          query: {
            ...route.query,
            'view-tab': 'opportunities',
          },
        });
      },
    },
    {
      key: 'processes',
      icon: 'kanban',
      label: 'Process',
      onClick: () => {
        activeViewTab.value = 'processes';
        router.replace({
          query: {
            ...route.query,
            'view-tab': 'processes',
          },
        });
      },
    },
  ];

  const viewTabsKeys = viewTabs.map(tab => tab.key);
  const activeViewTab = ref(viewTabsKeys.includes(route.query['view-tab'] || '') ?  route.query['view-tab'] : viewTabs[0].key);

  const activeTabs = ref<number[]>([]);

  const queryClient = useQueryClient();

  const accordion = ref<HTMLDivElement | null>(null);
  const accordionBoundary = useElementBounding(accordion);

  const container = ref<HTMLDivElement | null>(null);
  const { y: containerScroll } = useScroll(container);

  const page = ref(1);
  const identifier = ref(+new Date());
  const processes = ref<Process[]>([]);

  const isCreatingProcess = ref(false);
  const isEditingContacts: Ref<boolean> = ref(false);
  const isEditingDetails: Ref<boolean> = ref(false);
  const isEditingDescription: Ref<boolean> = ref(false);
  const isEditingEmployees: Ref<boolean> = ref(false);

  const contactsCount = computed(() => {
    return [...props.profile?.email ? [props.profile?.email] : [], ...(props.profile?.emails || [])]?.length + [...props.profile?.phone ? [props.profile?.phone] : [], ...(props.profile?.phones || [])]?.length + Object.values({
      ...props.profile?.social?.welcomeToTheJungle && { welcomeToTheJungle: props.profile?.social?.welcomeToTheJungle },
      ...props.profile?.social?.website && { website: props.profile?.social?.website },
      ...props.profile?.social?.linkedin && { linkedin: props.profile?.social?.linkedin },
    }).length;
  });

  const employees = ref([]);
  const { refetch: refetchEmployees } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['GET_COMPANY_CONTACTS', companyId.value],
    queryFn: async() => {
      try {
        const { data } = await getCompanyContacts(companyId.value);
        employees.value = data;

        if (employees.value?.length > 0) {
          activeTabs.value.push(2);
        }

        return employees.value;
      } catch (e) {
        console.error(e);
      }
    },
  });
  const employeesCount = computed(() => {
    return employees.value?.length || 0;
  });

  const descriptionCount = computed(() => {
    return props.profile.description ? 1 : 0;
  });

  const details = computed(() => [
    ...props.profile.locations || [],
    ...props.profile.size > 0 ? [props.profile.size] : [],
  ]);
  const detailsCount = computed(() => {
    return details.value?.length || 0;
  });

  const opportunities = ref<Opportunity[]>([]);
  const opportunitiesCount = computed(() => {
    return opportunities.value?.length || 0;
  });
  const opportunitiesTabsMap = [
    {
      label: t('global.all'),
      query: null,
    },
    {
      label: t('global.closed'),
      query: { active: false },
    },
    {
      label: t('global.open'),
      query: { active: true },
    },
  ];

  const opportunitiesActiveTab = ref<number>(2);
  const { isLoading: isOpportunitiesLoading, refetch: refetchOpportunities } = useQuery({
    queryKey: ['GET_COMPANY_OPPORTUNITIES', props.profile._id],
    queryFn: async() => {
      try {
        const { data } = await getCustomJobs({ companyId: companyId.value });

        const filteredJobs = opportunitiesTabsMap[opportunitiesActiveTab.value].query && data?.jobs?.length ? data.jobs.filter(job => job.active === opportunitiesTabsMap[opportunitiesActiveTab.value].query?.active) : data.jobs;

        opportunities.value = filteredJobs;

        if (opportunities.value?.length > 0) {
          activeTabs.value.push(3);
        }

        return filteredJobs;
      } catch (e) {
        console.error(e);
      }
    },
  });
  watch(() => opportunitiesActiveTab.value, () => {
    queryClient.invalidateQueries(['GET_COMPANY_OPPORTUNITIES', props.profile._id]);
  });

  async function loadMoreProcesses($state: any) {
    const data = await processesApi.getProcessesByResource({
      companyId: companyId.value,
      page: page.value,
      stepKey: processStatusFilter.value?.key,
      onlyActive: true,
    });
    if (!data) {
      $state.error();
      return;
    }
    const { processes: pageProcesses, isLastPage } = data;

    if (processes.value?.length > 0) activeTabs.value.push(4);

    if (pageProcesses?.length) {
      const uniqueNewProcesses = pageProcesses.filter(
        newProcess => !processes.value.some(existingProcess => existingProcess._id === newProcess._id)
      );
      processes.value = [...processes.value, ...uniqueNewProcesses];
      if (!isLastPage) page.value++;
      await nextTick();
      $state.loaded();
    }
    if (!pageProcesses?.length || isLastPage) {
      $state.complete();
    }
  }

  const updateProcess = ({ index, process }) => {
    $set(processes.value, index, { ...processes.value[index], ...process });
  };

  const removeProcessFromProcesses: ({ id }: {
    id: string
  }) => void = ({ id }) => {
    processes.value = processes.value.filter(process => process._id !== id);
  };

  const defaultOption = {
    name: t('processes.all-processes'),
    key: undefined,
    categoryKey: 'all',
  };
  processStatusFilter.value = defaultOption;

  const contracts = ref<Document[]>([]);
  const contractsCount = computed(() => {
    return contracts?.value?.length || 0;
  });

  const { refetch: refetchContracts } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['GET_CONTRACTS', companyId.value],
    queryFn: async() => {
      try {
        const { data } = await contractsController.getCompanyContracts(companyId.value);
        contracts.value = data;

        if (contracts.value?.length > 0) {
          activeTabs.value.push(5);
        }

        return contracts.value;
      } catch (e) {
        console.error(e);
      }
    },
  });
  const uploadDocument = ref<HTMLInputElement | null>(null);
  const triggerUploadButton = () => {
    uploadDocument.value?.click();
  };
  const addContract = async(e) => {
    if (e.target.files[0]) {
      const { data } = await contractsController.createCompanyContract({
        id: companyId.value,
        type: apiTypes.contract,
        file: e.target.files[0],
      });

      contracts.value.unshift(data);
    }
  };


  async function getCount() {
    processesCount.value = await processesApi.getProcessesCountByResource({
      companyId: companyId.value,
      stepKey: processStatusFilter.value?.key,
      onlyActive: true,
    });
  }

  async function resetProcesses() {
    processes.value = [];
    page.value = 1;
    identifier.value = +new Date();
    await getCount();
  }


  const processesCount = ref(1);

  getCount();

  watch(() => props.profile?._id, () => {
    if (!props.profile?._id) return;
    const initialActiveTabs = [
      contactsCount.value,
      detailsCount.value,
      employeesCount.value,
      opportunitiesCount.value,
      processesCount.value,
      contractsCount.value,
      descriptionCount.value,
    ];
    activeTabs.value = initialActiveTabs.map((_, index) => index).filter((index) => initialActiveTabs[index] > 0);
  }, { immediate: true });

  watch(
    () => [processStatusFilter.value],
    () => {
      resetProcesses();
    },
  );

  watch(() => route.params.id, () => {
    refetchEmployees();
    refetchOpportunities();
    resetProcesses();
    refetchContracts();
  });

  watch(() => activeViewTab.value, () => {
    if (activeViewTab.value !== 'processes' && processes.value?.length) {
      resetProcesses();
    }
  });
</script>

<template>
  <div ref="container" class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <PanelCarousel is-new-style>
      <PanelCompanyTop
        :company="profile"
        :employees="employees"
        @update-company="$emit('update-company', $event)"
      />
    </PanelCarousel>

    <div class="mt--4">
      <ViewsTabs :tabs="viewTabs" :forRoute="false" :active="activeViewTab"></ViewsTabs>
    </div>

    <MvAccordion
      :key="profile._id"
      ref="accordion"
      :active-index.sync="activeTabs"
      class="mt-4 flex flex-col gap-2.5 px-[20px]" multiple>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('panel-coder-edit.contact-information') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ contactsCount }}
            </div>
            <div v-show="!isEditingContacts" class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
                 @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingContacts = true;
                  activeTabs.push(0);
                }">
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCompanyContacts
          :company="profile" :is-editing="isEditingContacts" @close-edit="isEditingContacts = false"
          @update-company="$emit('update-company', $event)"/>
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ detailsCount }}
            </div>
            <div v-show="!isEditingDetails" class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
                 @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingDetails = true;
                  activeTabs.push(1);
                }">
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCompanyDetails
          :company="profile" :is-editing="isEditingDetails" @close-edit="isEditingDetails = false"
          @update-company="$emit('update-company', $event)"/>
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Contacts
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ employeesCount }}
            </div>
            <div v-show="!isEditingEmployees" class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
                 @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingEmployees = true;
                  activeTabs.push(2);
                }">
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCompanyEmployees
          :company="profile" :employees="employees" :is-editing="isEditingEmployees"
          @close-edit="isEditingEmployees = false" @update-employees="employees = $event"/>
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[1].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('global.opportunities') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ opportunitiesCount }}
            </div>
            <div class="absolute right-0 flex items-center gap-2.5 bg-white pl-2.5" @click.stop>
              <RouterLink
                :to="{
                name: $route.name,
                params: {
                  id: 'new-custom-jobs',
                  from: 'panel',
                  initData: {
                    companyId: profile._id,
                    selectedCompany: profile
                  }
                },
                query: {
                  ...route.query,
                  type: 'create-custom-jobs',
                  from: 'panel-company',
                  companyId: profile._id,
                },
              }">
                <button
                  class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500">
                  <i class="icon-plus-circle text-3xl"/>
                </button>
              </RouterLink>
            </div>
          </div>
        </template>
        <PanelCompanyOpportunities
          :active-tab="opportunitiesActiveTab" :company="profile"
          :is-loading="isOpportunitiesLoading" :opportunities="opportunities"
          :opportunities-tabs="opportunitiesTabsMap"
          @set-active-tab="opportunitiesActiveTab = $event"/>
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[2].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Process
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ processesCount }}
            </div>
            <div class="absolute right-0 flex items-center gap-2.5 bg-white pl-2.5" @click.stop>
              <ProcessDropdown
                :active-tabs="activeTabs" :items="statusFilterOptions"
                :parent-height="accordionBoundary.height" :scroll-position="containerScroll"
                :value="processStatusFilter"
                class="min-w-[160px]" @select="processStatusFilter = $event">
                <template #icon>
                  <span class="icon-equalizer mr-2 text-blue-800"></span>
                </template>
              </ProcessDropdown>
              <RouterLink
                :to="{
                name: $route.name,
                params: {
                  id: profile._id,
                },
                query: {
                  ...route.query,
                  type: 'process-kanban',
                  subtype: 'companies',
                },
              }">
                <BcButton class="!h-[32px] !text-sm !font-medium" color="success" icon-left="kanban"
                          size="extra-small">
                  {{ $t('generics.kanban') }}
                </BcButton>
              </RouterLink>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isCreatingProcess = true;
                  activeTabs.push(5);
                }">
                <i class="icon-plus-circle text-3xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCompanyProcess
          v-if="activeViewTab === viewTabs[2].key"
          :company="profile"
          :identifier="identifier"
          :is-editing="isCreatingProcess"
          :processes="processes"
          class="mt-2"
          @close-edit="isCreatingProcess = false"
          @create-process="() => {
            resetProcesses();
            isCreatingProcess = false;
          }"
          @update-process="updateProcess"
          @load-more-processes="loadMoreProcesses"
          @remove-process="removeProcessFromProcesses"
        />
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key" :class="{ 'panel-company-profile__documents': contractsCount > 0 }">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Documents
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ contractsCount }}
            </div>
            <div class="absolute right-0 flex gap-2.5 bg-white pl-2.5" @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton">
                <i class="icon-plus-circle text-3xl"/>
              </button>
              <input
                ref="uploadDocument" accept=".doc,.docx,application/pdf" class="hidden"
                name="document" type="file"
                @change="addContract"/>
            </div>
          </div>
        </template>
        <PanelCompanyDocuments :documents="contracts" @update-company="refetchContracts"/>
      </MvAccordionTab>
      <MvAccordionTab v-show="activeViewTab === viewTabs[0].key">
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Description
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white">
              {{ descriptionCount }}
            </div>
            <div v-show="!isEditingDescription"
                 class="absolute right-0 flex gap-2.5 bg-white pl-2.5" @click.stop>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingDescription = true;
                  activeTabs.push(6);
                }">
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCompanyDescription
          :company="profile" :is-editing="isEditingDescription"
          @close-edit="isEditingDescription = false"
          @update-company="$emit('update-company', $event)"/>
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss" scoped>
  .panel-company-profile__documents {
    min-height: 50px;
  }
</style>
