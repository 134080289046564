<script lang="ts" setup>
import { Candidate } from '@/domains/models/Candidate';
import { defineProps, computed } from 'vue';
import PanelCoderExperiencesItem from './PanelCoderExperiences/PanelCoderExperiencesItem.vue';
interface Props {
  candidate: Candidate;
}

const props = defineProps<Props>();
const experiences = computed(() => props.candidate.lastKnownExperiences?.experiences);
</script>

<template>
  <div class="flex flex-col gap-2 w-full">
    <PanelCoderExperiencesItem v-for="experience in experiences" :key="experience._id" :experience="experience" />
  </div>
</template>
1``