<template>
  <div
    class="card-companies is-full-width is-column is-overflow-hidden border-radius-m is-relative"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.exact="handleClick"
    @click.meta.exact="handleClickNewTab">
    <div
      class="card-companies__container is-full-width is-align-items-start is-relative">
      <div class="card-companies__wrapper card-companies__checkbox" @click.stop>
        <bc-checkbox
          :id="cardContent._id"
          :value="isSelected"
          @input="$emit('checked', $event)">
        </bc-checkbox>
      </div>
      <div
        class="card-companies__wrapper is-full-width is-column card-companies__id is-align-items-center is-justify-content-center">
        <div class="is-secondary-dark is-align-items-center is-full-width">
          <bc-avatar
            :src="cardContent.logoUrl"
            class="card-companies__avatar is-unshrink"
            size="s"
            @error="setAlternativeImg">
          </bc-avatar>
          <div
            class="card-companies__name is-column is-align-items-start"
            @click.stop>
            <p
              v-tooltip="cardContent.name"
              class="card-companies__text font-weight-bold font-size-m is-ellipsis is-full-width"
              @click.exact="
                openPanel({
                  id: cardContent._id,
                  type: 'companies',
                })
              ">
              {{ cardContent.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        :class="{ 'card-companies__empty': openJobsCount < 1 }"
        class="card-companies__wrapper card-companies__jobs is-align-items-center hidden-xs hidden-sm"
        @mouseenter="isHoverItem = true"
        @mouseleave="isHoverItem = false">
        <router-link
          v-if="!isOnSubscriptionsPage"
          v-tooltip="openJobsCount"
          :to="jobsLink"
          class="card-companies__link is-ellipsis is-full-width"
          @click.stop>
          {{ openJobsText }}
        </router-link>
      </div>
      <div
        class="card-companies__wrapper card-companies__socials is-align-items-center hidden-xs hidden-sm">
        <a
          v-tooltip="
            $t('companies.panel-company-contracts.open-the-linkedin-profile')
          "
          :class="{
            'card-companies__link--disabled': !(cardContent.social || {})
              .linkedin,
          }"
          :href="(cardContent.social || {}).linkedin"
          class="font-weight-bold font-size-xl mr-2"
          target="_blank"
          @click.stop>
          <img src="@/assets/logo/logo_linkedin.svg"/>
        </a>
        <a
          v-if="!isOnSubscriptionsPage"
          v-tooltip="
            $t('companies.panel-company-contracts.open-the-welcome-profile')
          "
          :class="{
            'card-companies__link--disabled': !(cardContent.social || {})
              .welcomeToTheJungle,
          }"
          :href="(cardContent.social || {}).welcomeToTheJungle"
          class="font-size-xl mr-2"
          target="_blank"
          @click.stop>
          <img src="@/assets/logo/wttj.png"/>
        </a>
        <a
          v-if="!isOnSubscriptionsPage"
          v-tooltip="
            $t('companies.panel-company-contracts.open-the-website')
          "
          :class="{
            'card-companies__link--disabled': !(cardContent.social || {})
              .website,
          }"
          :href="(cardContent.social || {}).website"
          class="icon-website font-size-xl"
          target="_blank"
          @click.stop>
        </a>
      </div>
      <div
        :class="{ 'card-companies__empty': openContactsCount < 1 }"
        class="card-companies__wrapper card-companies__jobs is-align-items-center hidden-xs hidden-sm"
        @mouseenter="isHoverItem = true"
        @mouseleave="isHoverItem = false">
        <div :style="{
          visibility: !isOnSubscriptionsPage && isSelected ? 'visible' : 'hidden',
          position: !isOnSubscriptionsPage && isSelected ? '' : 'absolute',
        }">
          <div>
            <MvSelect
              class="card-companies__contacts-select"
              :selected-label="`${$tc('generics.contacts-count', contactsFilterIds.length)}`"
              width="auto"
              direction="bottom"
              :items="contactOptions"
              :is-absolute="!!isPanel"
              :select-full-item="true"
              :with-avatar="true"
              :with-checkbox="true"
              @select="onSelectContact"
              @error="onAvatarError"
            >
              <template #content="{ item }">
                <div v-if="item.favorite">
                  <img :src="START_SIMPLE_SVG" />
                </div>
              </template>
            </MvSelect>
          </div>
        </div>
        <router-link
          v-if="!isOnSubscriptionsPage && !isSelected"
          v-tooltip="openContactsCount"
          :to="contactsLink"
          class="card-companies__link is-ellipsis is-full-width"
          @click.stop>
          {{ $tc('generics.contacts-count', openContactsCount) }}
        </router-link>
      </div>
      <div class="card-companies__wrapper card-companies__locations">
        <card-tags
          :reference="'locations'"
          :tags="locations"
          class="card-companies__tag-wrapper">
          <template #tag="{ tag }">
            <tag
              v-tooltip="{ content: [... new Set([tag.completeAddress ? tag.completeAddress : tag.fullAddress])].join(', ') }"
              :text="tag.fullAddress"
              :text-limit="13"
              type="location">
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="false" :text="`+${remaining}`" type="location"></tag>
          </template>
        </card-tags>
      </div>
      <div
        v-if="!isOnSubscriptionsPage"
        class="card-companies__activity card-companies__status-select-container"
        @mouseenter="isHoverStatus = true"
        @mouseleave="isHoverStatus = false">
        <panel-company-header-prospection-status
          :profile="cardContent"
          class="card-companies__status-select"
        >
        </panel-company-header-prospection-status>
        <!-- <mv-select
          class="card-companies__status-select"
          value="item2"
          :items="[{ label: 'item1', value: 'item1' }, { label: 'item2', value: 'item2' }]"
        ></mv-select> -->
      </div>

      <div
        v-if="!isOnSubscriptionsPage"
        class="card-companies__wrapper card-companies__prospecting is-align-items-center hidden-xs ml-5">
        <bc-avatar
          v-if="cardContent._owner"
          v-tooltip="
            `${cardContent._owner.firstName} ${cardContent._owner.lastName}`
          "
          :placeholder="userInitials"
          :src="cardContent._owner.pictureUrl"
          class="card-companies__follower is-unshrink mr-3"
          size="s">
        </bc-avatar>
      </div>
      <div
        v-if="isOnSubscriptionsPage"
        class="card-companies__wrapper card-companies__time-slot is-align-items-center hidden-xs hidden-sm">
        <p
          v-if="cardContent.calendly && cardContent.calendly.timeSlot"
          class="card-companies__text is-secondary-dark">
          {{ timeSlot }}
        </p>
      </div>
      <div
        v-if="isOnSubscriptionsPage"
        class="card-companies__wrapper card-companies__button is-align-items-center hidden-xs">
        <bc-button
          v-if="!isCompanySupported"
          class="card-companies-informations__button"
          size="small"
          @click.prevent.stop="setOwner">
          {{ $t('companies.pipe-card.take-care-of') }}
        </bc-button>
      </div>
    </div>
  </div>
</template>

<script>
  import PanelNavigationMixin from '@/common-old/mixins/Panel/panelNavigation';
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import Tag from '@/components/Tag/Tag';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import CardTags from '@/components/Card/CardTags';
  import moment from 'moment/min/moment-with-locales';
  import company from '@/api/company';
  import { status } from '@/common-old/macros/jobs';
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import PanelCompanyHeaderProspectionStatus
    from '@/components/Panel/PanelCompany/PanelCompanyHeader/PanelCompanyHeaderProspectionStatus';
  import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
  import MvSelect from '@/ui-kit/components/MvSelect/MvSelect';
  import { mapActions } from 'vuex';
  const DEFAULT_COACH_AVATAR = require('@/assets/img/default-avatar.svg');
  const START_SIMPLE_SVG = require('@/assets/svg/star_simple.svg');

  export default {
    name: 'card-companies',
    components: {
      BcCheckbox,
      CardTags,
      BcButton,
      Tag,
      BcAvatar,
      PanelCompanyHeaderProspectionStatus,
      MvSelect,
    },
    mixins: [PanelNavigationMixin],
    props: {
      cardContent: {
        type: Object,
        default: () => ({}),
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isHover: false,
        isHoverStatus: false,
        isHoverItem: false,
        macros: {
          status,
        },
        contactsFilterIds: [],
        START_SIMPLE_SVG,
      };
    },
    computed: {
      isPanel() {
        return this.$route.params?.id;
      },
      profile() {
        return this.$store.state.user.profile;
      },
      userInitials() {
        return `${this.cardContent?._owner?.firstName?.charAt(
          0,
        )}${this.cardContent?._owner?.lastName?.charAt(0)}`;
      },
      isOnSubscriptionsPage() {
        return this.$route.name.includes('InboxCardList');
      },
      locations() {
        return this.cardContent?.locations?.map((location) => formatPlaceDetailsToBaseLocation(location, 'locality'));
      },
      followers() {
        return this.cardContent.followers?.slice(0, 1) || [];
      },
      followersTooltip() {
        return {
          content: this.cardContent.followers
          ?.map(follower => `${follower.firstName} ${follower.lastName}`)
          ?.join(', '),
        };
      },
      jobsLink() {
        return {
          name: this.$route.name.includes('Panel')
            ? this.$route.name
            : `${this.$route.name}Panel`,
          params: {
            id: this.cardContent._id,
          },
          query: {
            ...this.$route.query,
            type: 'companies',
            category: 'jobs',
          },
        };
      },
      contactsLink() {
        return {
          name: this.$route.name.includes('Panel')
            ? this.$route.name
            : `${this.$route.name}Panel`,
          params: {
            id: this.cardContent._id,
          },
          query: {
            ...this.$route.query,
            type: 'companies',
            category: 'contacts',
          },
        };
      },
      lastCoachActivity() {
        return this.cardContent.lastCoachActivity
          ? moment(this.cardContent.lastCoachActivity).format('L') : '';
      },
      isCompanySupported() {
        return !!this.cardContent._owner;
      },
      openJobsCount() {
        // return this.cardContent?._customJobs?.filter(customJob => !customJob.deletedAt && customJob.active)?.length || 0;
        return this.cardContent?.customJobsCount || 0;
      },
      openContactsCount() {
        // return this.cardContent?._contacts?.filter(contact => !contact.deletedAt)?.length || 0;
        return this.cardContent?.contactsCount || 0;
      },
      openJobsText() {
        return this.openJobsCount > 1
          ? `${this.openJobsCount}  ${this.$i18n.tc('generics.opportunity', 2)}`
          : `${this.openJobsCount} ${this.$i18n.tc('generics.opportunity', 1)}`;
      },
      timeSlot() {
        return localStorage.locale === 'fr' ? moment(((this.cardContent || {}).calendly || {}).timeSlot).format(
            'DD/MM HH:mm',
          )
          : moment(((this.cardContent || {}).calendly || {}).timeSlot).format(
            'MM/DD HH:mm',
          );
      },
      contactOptions() {
        const contacts = this.cardContent?._contacts || [];
        return contacts.map(contact => ({
          _id: contact._id,
          label: `${contact.firstName} ${contact.lastName}`,
          value: contact._id,
          enabled: false,
          avatar: contact.pictureUrl || DEFAULT_COACH_AVATAR,
          selected: this.contactsFilterIds.includes(contact._id),
          favorite: contact.favorite,
          email: contact.email,
          emails: contact.emails,
        }));
      },
      appParams() {
        return this.$store.state.app.params;
      }
    },
    mounted() {
      const ids = this.cardContent?._contacts?.map(contact => contact._id) || [];
      this.contactsFilterIds = ids;
    },
    methods: {
      ...mapActions({
        setParams: 'app/setParams',
      }),
      handleClick() {
        if (this.isHoverStatus || this.isHoverItem) return;
        this.openPanel({ id: this.cardContent._id, type: 'companies' });
      },
      handleClickNewTab() {
        if (this.isHoverStatus || this.isHoverItem) return;
        this.openPanelInNewTab({
          id: this.cardContent._id,
          type: 'companies',

        });
      },
      setAlternativeImg(event) {
        event.target.src = require('@/assets/img/default-avatar-company.svg');
      },
      async setOwner() {
        try {
          const { data } = await company.setPartnerOwner(
            this.cardContent._id,
            this.$store.state.user.profile._id,
          );

          if (this.isOnSubscriptionsPage) {
            this.$store.dispatch('removeCard', this.cardContent._id);
          } else {
            this.$store.dispatch('updateCardFromId', {
              id: this.cardContent._id,
              content: data,
            });
          }
        } catch (error) {
          this.$toast.show({
            title: this.$i18n.t(
              'companies.pipe-card.error-while-modifying-owner',
            ),
            message: this.$t('toast.error-occured'),
            type: 'error',
            icon: 'cross',
          });
        }
      },
      onSelectContact(selected) {
        const alreadySelected = this.contactsFilterIds.find(contactId => contactId === selected?._id);
        if (alreadySelected) {
          const ids = this.contactsFilterIds.filter(contactId => contactId !== selected?._id);
          this.contactsFilterIds = ids;
          this.setParams({
            ...this.appParams,
            [`selected_contacts_${this.cardContent._id}`]: ids,
          });
          // Update selectedCards in Vuex store
          this.$store.dispatch('updateSelectedCardFromId', {
            id: this.cardContent._id,
            content: { selectedContacts: ids }
          });
          return;
        }
        const ids = [...this.contactsFilterIds, selected?._id];
        this.contactsFilterIds = ids;
        this.setParams({
          ...this.appParams,
          [`selected_contacts_${this.cardContent._id}`]: ids,
        });
        // Update selectedCards in Vuex store
        this.$store.dispatch('updateSelectedCardFromId', {
          id: this.cardContent._id,
          content: { selectedContacts: ids }
        });
      },
      onAvatarError(event) {
        event.target.src = DEFAULT_COACH_AVATAR;
      },
    },
    watch: {
      isSelected(selected) {
        this.setParams({
          ...this.appParams,
          [`selected_contacts_${this.cardContent._id}`]: selected ? this.contactsFilterIds : undefined,
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  $colors: (
    'unsigned': (
      $color-error,
    ),
    'signed': (
      $color-success,
    ),
    'prospection': (
      $color-secondary-dark,
    ),
    'qualifying': (
      $color-secondary,
    ),
    'sent-conditions': (
      $color-secondary-light,
    ),
    'to-recontact': (
      $color-primary-dark,
    ),
    'denounced': (
      $color-error-light,
    ),
    'stand-by': (
      $color-warning,
    ),
    'liquidated': (
      $color-grey-5,
    ),
    'self': (
      $color-primary,
    ),
  );

  .card-companies {
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      .#{$name} {
        background: $color;
      }
    }

    &:hover {
      cursor: pointer;
      max-height: initial;
    }

    @include bp('tablet') {
      max-height: 100px;
    }

    &__container {
      padding: 15px;
      flex-direction: column;

      @include bp('tablet') {
        flex-direction: row;
      }

      @include bp('desktop') {
        // padding: 25px 15px;
      }
    }

    &__empty > a {
      color: $color-blue-dark;
    }

    &__checkbox {
      align-items: center;
    }

    &__wrapper {
      margin: 10px;
      max-height: 100%;
      min-height: 37px;
      width: 100%;

      @include bp('tablet') {
        margin: 0 10px;
        width: initial;
      }
    }

    &__status-select {
      &-container {
        height: 100%;
      }
    }

    &__id {
      @include companies-grid-name;
    }

    &__avatar {
      margin-right: 10px;
    }

    &__name {
      max-width: calc(100% - 50px);
    }

    &__divider {
      margin: 0.75em 0;
      border-top: 1px solid $color-grey-2;
    }

    &__socials {
      @include companies-grid-socials;
      color: $color-blue-dodger;

      a {
        color: $color-blue-dodger;
        align-items: center;
        justify-content: center;
        display: flex;

        & > img {
          width: 20px;
          height: 20px;
        }

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }

    &__collaborators {
      @include companies-grid-collaborators;
    }

    &__link {
      color: $color-primary;

      &--disabled {
        text-decoration: initial;
        pointer-events: none;
        opacity: 0.5;
      }

      &:hover {
        text-decoration: initial;
      }
    }

    &__locations {
      @include companies-grid-locations;
    }

    &__text {
      max-width: 100%;
    }

    &__tag-wrapper {
      width: 100%;
    }

    &__followers {
      flex-grow: 1;
      @include companies-grid-lists;
    }

    &__follower {
      margin-left: -5px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__activity {
      @include companies-grid-activity;
    }

    &__prospecting {
      @include companies-grid-prospecting;
    }

    &__time-slot {
      @include companies-grid-time-slot;
    }

    &__button {
      @include companies-grid-button;
    }

    &__jobs {
      @include companies-grid-jobs;
    }

    &__status {
      height: 5px;
      z-index: 2;

      &--signed {
        background: $color-success;
      }

      &--unsigned {
        background: $color-error;
      }

      &--stand-by {
        background: $color-warning;
      }
    }

    &__contacts-select {
      width: 135px;

      &:deep() {
        .mv-select__btn {
          border-radius: 30px;
        }
      }
    }
  }
</style>
