import { i18n } from '../i18n/i18n';
import { FILES_INFOS } from './documents';
const DEFAULT_COMPANY_AVATAR = require('@/assets/img/default-avatar-company.svg');

export const mask = (cc, num = 4, mask = '*') =>
  `${cc}`.slice(-num).padStart(`${cc}`.length, mask);

export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0–9._-]+@[a-zA-Z0–9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export function formatBytes(x){
  let l = 0, n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l){
    n = n/1024;
  }
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const formatEmail = (content = {}) => {
  const senderName = `${content?.sender?.firstName} ${content?.sender?.lastName}`;
  const senderEmail = content.sender.email;

  const recipientName = `${content?.receiver?.firstName} ${content?.receiver?.lastName || ''}`;
  const recipientEmail = content.receiver.email;

  const to = i18n.t('generics.to');
  const object = i18n.t('email.object');

  return `
    <div class="is-column w-full">
      <div class="is-column gap-[10px] rounded-t-md">
        <div class="gap-1 font-bold text-blue-800">
          <span>${senderName}</span><span class="font-normal text-blue-400">${ '&lt;' + senderEmail + '&gt;' }</span>
        </div>
        <div class="gap-1 font-bold text-blue-800">
          <span class="font-normal">${to}</span> : <span>${recipientName}</span><span class="font-normal text-blue-400">${ '&lt;' + recipientEmail + '&gt;' }</span>
        </div>
        ${content?.cc?.length ? (
          `<div class="w-full gap-1">
            <div class="flex-0 font-bold text-blue-800">CC : </div>
            <div class="is-column gap-1">
              ${content.cc.map((email) => (
                `<div class="gap-1">
                  <span class="font-bold">${email.name}</span><span class="font-normal text-blue-400">${'&lt;' + email.address + '&gt;'}</span>
                </div>`
              )).join('')}
            </div>
          </div>`
        ) : ''}
      </div>
      <div class="block border-t border-blue-200 mt-4">
        <div class="gap-1 font-bold text-blue-800 mt-2">
          ${object} : <span class="font-normal text-blue-500">${content.subject.replaceAll('{{', '{').replaceAll('}}', '}')}</span>
        </div>
        <div class="mt-4 block">
          <div class="block mv-text-editor-preview">${content.html.replaceAll('{{', '{').replaceAll('}}', '}')}</div>
        </div>
      </div>

      ${content?.attachments?.length ? (
        `<div class="flex flex-wrap gap-2 mt-3">
            ${content.attachments.map((attachment) => {
              const fileExtension = attachment?.filename.split('.');
              const extension = fileExtension[fileExtension.length - 1];
              const fileInfo = FILES_INFOS[extension] || FILES_INFOS.doc;

              return (
                `<div
                  class="h-[51px] w-[48%] items-center rounded-md bg-neutral-200 p-2 hover:cursor-pointer"
                  onclick="window.open('${attachment.href}', '__blank');"
                >
                  <div class="min-w-[30px] size-[30px] items-center justify-center rounded-md" class="${fileInfo.class}">
                    <img class="size-[20px] object-contain" src="${fileInfo.logo}" />
                  </div>
                  <div class="ml-2 w-full items-center justify-between truncate">
                    <p class="text-xs font-medium truncate">${attachment.filename}</p>
                  </div>
                </div>`
              )
            }).join('')}
          </div>`
      ) : ''}
    </div>
  `;
}

export const formatEmailCompanies = (content = {}) => {
  const companies = content?.companies || [];
  const object = i18n.t('email.object');

  return `
    <div class="is-column w-full">
      <div class="is-column gap-[10px]">
        ${companies?.length ? (
          `<div class="flex gap-2">
              ${companies.map((company) => (
                `<div class="flex items-center gap-1 bg-blue-200 w-fit rounded-[40px] h-5 pl-1 pr-2 flex-wrap">
                  <img src="${company.logoUrl}" class="rounded-full" style="width: 16px; height: 16px;" onerror="this.src='${DEFAULT_COMPANY_AVATAR}'" />
                  <span class="font-bold text-blue-500">${company.name}</span>
                </div>`
              )).join('')}
            </div>`
        ) : ''}
      </div>
      <div class="block border-t border-blue-200 mt-4">
        <div class="gap-1 font-bold text-blue-800 mt-2">
          ${object} : <span class="font-normal text-blue-500">${content.subject.replaceAll('{{', '{').replaceAll('}}', '}')}</span>
        </div>
        <div class="mt-4 block">
          <div class="block mv-text-editor-preview">${content.html.replaceAll('{{', '{').replaceAll('}}', '}')}</div>
        </div>
      </div>

      ${content?.attachments?.length ? (
        `<div class="flex flex-wrap gap-2 mt-3">
            ${content.attachments.map((attachment) => {
              const fileExtension = attachment?.filename.split('.');
              const extension = fileExtension[fileExtension.length - 1];
              const fileInfo = FILES_INFOS[extension] || FILES_INFOS.doc;

              return (
                `<div
                  class="h-[51px] w-[48%] items-center rounded-md bg-neutral-200 p-2 hover:cursor-pointer"
                  onclick="window.open('${attachment.href}', '__blank');"
                >
                  <div class="min-w-[30px] size-[30px] items-center justify-center rounded-md" class="${fileInfo.class}">
                    <img class="size-[20px] object-contain" src="${fileInfo.logo}" />
                  </div>
                  <div class="ml-2 w-full items-center justify-between truncate">
                    <p class="text-xs font-medium truncate">${attachment.filename}</p>
                  </div>
                </div>`
              )
            }).join('')}
          </div>`
      ) : ''}
    </div>
  `;
}

export function removeTags(htmlString) {
  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Extract text content
  const textContent = doc.body.textContent || "";
  // Trim whitespace
  return textContent.trim();
}
