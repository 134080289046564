<template>
  <div class="panel-timeline">
    <PanelCoderProspectsTimeline
      v-if="isProspectsTimeline && profile?._id"
      :coder="profile"/>
    <timeline-list
      :timeline="timeline"
      @note-deleted="deleteNote"
      @note-pinned="pinNote"
      @note-edited="editNote">
    </timeline-list>
    <infinite-loading
      :distance="400"
      :identifier="identifier"
      class="panel-timeline__infinite-scroll"
      @infinite="getTimeline">
      <spinner slot="spinner"></spinner>
      <p
        slot="no-more"
        class="panel-timeline__text">
      </p>
      <p
        slot="no-results"
        class="panel-timeline__text">
        {{ $t('timeline.no-result') }}
      </p>
      <p
        slot="error"
        class="panel-timeline__text">
        {{ $t('timeline.error-during-request') }}
      </p>
    </infinite-loading>
  </div>
</template>

<script>
  import TimelineList from '@/components/Timeline/TimelineList';
  import Spinner from '@/legacy/ui-kit/components/Spinner/BcSpinner';
  import InfiniteLoading from 'vue-infinite-loading';
  import { getUserTimeline } from '@/api/timeline';
  import { mapStores } from 'pinia';
  import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';
  import PanelCoderProspectsTimeline from '@/components/Panel/PanelCoder/PanelCoderTemp/PanelCoderProspectsTimeline.vue';

  const routes = {
    companies: 'partners',
    coders: 'coders',
    contacts: 'contacts',
    hunt: 'hunt',
    jobs: 'jobs',
    pokes: 'pokes',
    process: 'process',
    'suggestions-coders': 'coders',
    'my-profile': 'coaches',
    coaches: 'coaches',
  };

  export default {
    name: 'panel-timeline',
    components: {
      Spinner,
      TimelineList,
      InfiniteLoading,
      PanelCoderProspectsTimeline,
    },
    props: {
      userType: {
        type: String,
        default: '',
      },
      timeline: {
        type: Array,
        default: () => [],
      },
      profile: {
        type: Object,
        default: () => {},
      },
      identifier: {
        type: Number,
        default: +new Date(),
      },
      page: {
        type: Number,
        default: 1,
      },
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        retry: 0,
      };
    },
    computed: {
      timelineId() {
        if (this.id) {
          return this.id;
        }

        return this.$route.params.id === 'profile' ? this.$store.state.user.profile._id : this.$route.params.id;
      },
    ...mapStores(useLaunchDarklyStore),
      isCampaignsVisible() {
      return this.launchDarklyStore?.getFlag('enableCampaigns') ?? false;
    },
      isProspectsTimeline() {
        return this.$route.query?.['timeline-type'] === 'all' && this.isCampaignsVisible;
      },
    },
    methods: {
      async getTimeline($state) {
        try {
          if (['new-coder', 'new-contact'].includes(this.timelineId)) {
            $state.loaded();
            $state.complete();
            return;
          }

          const { data: { timeline, next } } = await getUserTimeline({
            userType: this.$route.query.type === 'my-profile' || this.userType === 'coach' ? 'coaches' : routes[this.$route.query.type],
            id: this.timelineId,
            page: this.page,
            ...(this.$route.query['timeline-type']) && { type: this.$route.query['timeline-type'] },
          });


          this.$emit('timeline-loaded', [...this.timeline, ...timeline]);

          if (next) {

            this.$emit('timeline-incomplete');

            $state.loaded();
          } else {
            $state.loaded();

            $state.complete();
          }
        } catch (error) {
          if (error.message !== 'Operation canceled due to new request.') {
            $state.error();
          } else if (this.retry < 3) {
            this.retry++;
            this.getTimeline($state);
          }

        }
      },
      editNote(note) {
        this.$emit('note-edited', note);
      },
      deleteNote(id) {
        this.$emit('note-deleted', id);
      },
      pinNote(note) {
        this.$emit('note-pinned', note);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-timeline {
    width: 100%;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    height: fit-content;
    align-items: center;

    &__infinite-scroll {
      justify-content: center;
      padding: 25px 0;
      margin-top: -25px;
    }

    &__text {
      font-size: $font-size-xs;
      color: $color-blue-dark-cello;
    }
  }
</style>
