<template>
  <views class="database-partners col-xs-12 is-align-items-center">
    <companies-tabs></companies-tabs>
    <router-view></router-view>
    <views-container>
      <searchbar-partners
        :results-count="queryCount"
        class="database-partners__searchbar"
        partners-searchbar>
      </searchbar-partners>
      <companies-empty
        v-if="isLoaded && cards.length === 0 && !globalMetadata.partners"
        class="database-partners__empty"
        @import="isModalOpen = true">
      </companies-empty>
      <div
        v-else
        class="is-column">
        <bc-spinner
          v-if="!isLoaded"
          class="database-partners__spinner">
        </bc-spinner>
        <div
          v-else
          class="is-align-items-center is-column">
          <companies-grid
            :is-checked="isAllCardsSelected"
            class="hidden-xs"
            @checked="selectAllCards">
          </companies-grid>
          <card-list
            ref="cardList"
            :cards="cards"
            :is-loaded="true"
            class="pagination__card-list">
            <template #card="{ card }">
              <card
                :card-content="card"
                card-type="partners">
                <card-companies
                  :card-content="card"
                  :is-selected="getIsSelectedStatus(card._id)"
                  @checked="setSelectedCards($event, card)">
                </card-companies>
              </card>
            </template>
          </card-list>
        </div>
        <bc-pagination
          :current.sync="currentPage"
          :total="queryCount"
          class="database-partners__pagination">
        </bc-pagination>
        <multiselect-footer
          v-if="selectedCards.length"
          class="database-partners__footer is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
        </multiselect-footer>
      </div>
    </views-container>
    <bc-modal
      :active="isModalOpen"
      @close="isModalOpen = false">
      <database-companies-modal-data-import-button @close-modal="isModalOpen = false">
      </database-companies-modal-data-import-button>
    </bc-modal>
    <CampaignsModal />
  </views>
</template>

<script>
  import axios from 'axios';
  import { mapActions, mapState } from 'vuex';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner';
  import BcPagination from '@/ui-kit/components/BcPagination';
  import CardList from '@/components/CardList/CardList';
  import Views from '@/components/Views/Views';

  import locations_helpers from '@/common-old/utils/locations_helpers';
  import salary from '@/common-old/macros/salary';
  import SearchbarPartners from '@/components/Searchbar/SearchbarPartners/SearchbarPartners';
  import CompaniesGrid from '@/components/Companies/CompaniesGrid';
  import Card from '@/components/Card/Card';
  import CardCompanies from '@/components/Card/CardCompanies';
  import MultiselectMixin from '@/mixins/Multiselect/multiselect';
  import MultiselectFooter from '@/components/Multiselect/MultiselectFooterLegacy';
  // import ViewsTopbar from '@/components/Views/ViewsTopbar';
  import ViewsContainer from '@/components/Views/ViewsContainer';
  import CompaniesEmpty from '@/components/Companies/CompaniesEmpty';
  import BcModal from '@/ui-kit/components/BcModal/BcModal';
  import DatabaseCompaniesModalDataImportButton
    from '@/components/DatabaseCompanies/DatabaseCompaniesModal/DatabaseCompaniesModalDataImport';
  import CompaniesTabs from '@/views/Tabs/CompaniesTabs';
  import CampaignsModal from '@/components/Campaigns/CampaignsModal.vue';


  export default {
    name: 'database-partners',
    components: {
      DatabaseCompaniesModalDataImportButton,
      BcModal,
      CompaniesEmpty,
      ViewsContainer,
      MultiselectFooter,
      CardCompanies,
      Card,
      CompaniesGrid,
      SearchbarPartners,
      BcSpinner,
      BcPagination,
      CardList,
      Views,
      CompaniesTabs,
      CampaignsModal,
    },
    mixins: [MultiselectMixin],
    data() {
      return {
        isLoaded: true,
        isModalOpen: false,
        currentPage: parseInt(this.$route.query.page) || 1,
        cancelTokenSource: undefined,
      };
    },
    computed: {
      ...mapState({
        cards: state => state.card.cards,
        sort: state => state.card.sort,
        queryCount: state => state.card.count,
        globalMetadata: state => state.user.metadata,
        technos: state => state.tags.technos,
        profile: state => state.user.profile,
      }),
      isSuperUser() {
        return this.$store.getters['user/isSuperUser'];
      },
      salaryRange() {
        return {
          ...this.$route.query.contracts && this.$route.query.contracts === 'freelance' && {
            salaryMin: salary.salaryMinFilterFreelance,
            salaryMax: salary.salaryMaxFilterFreelance,
          },
          ...this.$route.query.contracts && this.$route.query.contracts === 'cdi' && {
            salaryMin: salary.salaryMin,
            salaryMax: salary.salaryMax,
          },
        };
      },
      parameters() {
        const params = {
          page: this.$route.query.page ? this.$route.query.page : 1,
          status: this.$route.query.status === 'all-status' ? undefined : this.$route.query.status,
          ...this.$route.query.technos && { technos: this.$route.query.technos },
          ...this.$route.query.search && { query: this.$route.query.search },
          ...this.$route.query.contracts && this.$route.query.contracts !== 'all' && { contract: this.$route.query.contracts },
          ...this.$route.query.experiences && { experiences: this.$route.query.experiences },
          ...this.$route.query.partnership && { status: this.$route.query.status },
          ...this.$route.query.roles && { roles: this.$route.query.roles },
          ...this.$route.query.remotes && { remotes: this.$route.query.remotes },
          ...this.$route.query.domains && { domains: this.$route.query.domains },
          ...this.$route.query.ownerIds && { ownerIds: this.$route.query.ownerIds },
          ...this.$route.query.locations && { locations: JSON.stringify(locations_helpers.getLocationsId(this.$route.query.locations)) },
          ...this.$route.query.distance && { distance: this.$route.query.distance },
          ...this.$route.query.uniqueids && { uniqueids: this.$route.query.uniqueids.split(',').map(id => +id) },
          ...this.sort.field !== 'default' && this.sort.direction !== 'default' && {
            'sortField': this.sort.field,
            'sortDirection': this.sort.direction,
          },
          salaryMin: this.$route.query['salary-min'] && Number(this.$route.query['salary-min']) > this.salaryRange.salaryMin ? this.$route.query['salary-min'] : undefined,
          salaryMax: this.$route.query['salary-max'] && Number(this.$route.query['salary-max']) < this.salaryRange.salaryMax ? this.$route.query['salary-max'] : undefined,
        };

        const customFieldsQuery = Object.keys(this.$route.query).filter((key) => key.startsWith('cf-'));
        if (customFieldsQuery.length) {
          const obj = {};
          customFieldsQuery.forEach(key => {
            obj[key.split('cf-')[1]] = this.$route.query[key];
          });
          params['customFields'] = JSON.stringify(obj);
        }

        return params;
      },
    },
    created() {
      this.setCount(1);
      this.getPartners();
    },
    beforeDestroy() {
      this.cancelTokenSource.cancel('manualCancel');
      this.setParams({});
      this.resetSelectedCards();
    },
    methods: {
      ...mapActions([
        'resetCards',
        'getCards',
        'setCount',
      ]),
      ...mapActions({
        setParams: 'app/setParams',
      }),
      goNewCompany() {
        this.$router.push({
          name: `${this.$route.name}Panel`,
          params: {
            id: 'new-company',
          },
          query: {
            ...this.$route.query,
            type: 'create-company',
          },
        });
      },
      async getPartners() {
        this.isLoaded = false;

        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel('manualCancel');
        }

        this.cancelTokenSource = axios.CancelToken.source();

        try {
          this.resetCards();
          await this.getCards({
            page: this.$route.query.page,
            call: 'partners/lookup',
            parameters: this.parameters,
            responseKeyName: 'companies',
            cancelTokenSource: this.cancelTokenSource,
          });
          this.isLoaded = true;
        } catch (error) {
          this.$emit('error', error);

          this.isLoading = true;
        }
      },
    },
    watch: {
      $route: {
        handler(to = {}, from = {}) {
          // const isDifferentQueries = Object.keys(to.query).some(key => to.query[key] !== from.query[key]);
          const isOpeningPanel = Boolean(!(from.params || {}).id && (to.params || {}).id);
          const isClosingPanel = Boolean((from.params || {}).id && !(to.params || {}).id);
          const isInPanel = Boolean((from.params || {}).id && (to.params || {}).id);
          const pageChange = from.query.page !== to.query.page;

          const isChange = !isOpeningPanel && !isClosingPanel && (!isInPanel || (isInPanel && pageChange));
          const isClosing = isClosingPanel && !isOpeningPanel && !pageChange && !isInPanel;

          if (isChange || isClosing) {
            this.getPartners();
            this.currentPage = parseInt(to.query.page) || 1;
          }
        },
        deep: true,
      },
      sort() {
        this.currentPage = 1;
        this.getPartners();
      },
      currentPage(newPage) {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .database-partners {
    @include page_grid();

    &__title {
      min-height: 30px;
    }

    &__subtitle {
      position: relative;
      top: 1px;
      color: #3D5476;
    }

    &__searchbar {
      margin-bottom: 20px;
    }

    &__filters {
      margin-bottom: 10px;

      @include bp('desktop') {
        margin-bottom: 5px;
      }

      &__searchbar {
        margin-bottom: 20px;
      }
    }

    &__button {
      flex-shrink: 0;
    }

    &__pagination {
      margin: 20px 0;
    }

    &__spinner {
      margin: 100px 0;
    }

    &__card-list {
      margin-bottom: 100px;
    }

    &__spinner, &__pagination, &__card-list {
      justify-content: center;
    }
  }
</style>
