<template>
  <div class="auth-magic-login is-column">
    <div class="auth-magic-login__container is-align-items-center is-justify-content-center">
      <div class="auth-magic-login__wrapper is-column is-justify-content-center">
        <h2 class="auth-magic-login__title">
          {{ $t('login.log-in-to-your-workspace') }}
        </h2>
        <div class="social-button-block">
          <a :href="getGoogleUrl()" class="social-auth gap-6 px-6">
            <img src="@/assets/logo/logo_google.svg" alt="Google Logo"/>
            <span>{{ $t('generics.continue-with', { name: 'Google' }) }}</span>
          </a>
          <a :href="getMicrosoftUrl()" class="social-auth gap-6 px-6">
            <img src="@/assets/logo/logo_microsoft.svg" alt="Microsoft Logo"/>
            <span>{{ $t('generics.continue-with', { name: 'Microsoft' }) }}</span>
          </a>
        </div>
        <div class="separator-container">
          <div class="separator"></div>
          <p>ou</p>
          <div class="separator"></div>
        </div>
        <h2
          v-if="isError"
          class="auth-magic-login__title font-size-xl mb-6">
          Malheureusement, nous n’avons pas trouvé de compte associé à l’adresse email :
          <strong>{{ emailSet }}</strong>
        </h2>
        <ValidationObserver
          ref="observer"
          class="auth-magic-login__form is-full-width is-flex is-column is-align-items-center"
          tag="form"
          @submit.prevent="getToken">
          <ValidationProvider
            v-slot="{ errors, valid }"
            :rules="{ required: true, email: true }"
            name="Email"
            :mode="custom"
            slim>
            <bc-input
              ref="email"
              v-model="input"
              :class="{ 'invalid': errors.length > 0 }"
              :error="errors[0]"
              :valid="valid"
              class="auth-magic-login__input is-full-width mb-5 mt-0"
              name="email"
              icon="mail"
              label="Mail"
              placeholder="Mail"
              required
              type="text">
            </bc-input>
          </ValidationProvider>
          <bc-button
            class="mt-2 is-full-width auth-magic-login__button"
            native-type="submit">
            {{ $t('login.send') }}
          </bc-button>
        </ValidationObserver>
        <div class="auth-magic-login__signup">
          <a href="https://marvinrecruiter.com/creer-mon-espace"
             :title="$t('login.create-account')">
            {{ $t('login.sign-up') }}
          </a>
        </div>
      </div>
    </div>
    <p class="auth-magic-login__footer">
      {{ $t('login.footer-text') }}
    </p>
  </div>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  // import { } from '';

  import BcInput from '@/ui-kit/components/BcInput/BcInput';
  import BcButton from '@/ui-kit/components/BcButton/BcButton';
  import auth from '@/api/auth';

  export default {
    name: 'auth-magic-login',
    components: {
      BcButton,
      BcInput,
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      email: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isError: false,
        emailSet: '',
        googleRedirectURI: `${window.location.origin}/auth/login?next=close&s=gl`,
        linkedinRedirectURI: `${window.location.origin}/auth/login?next=close&s=lk`,
        microsoftRedirectURI: `${window.location.origin}/auth/login?next=close&s=ms`,
      };
    },
    created() {
      const availableJumps = {
        google: this.getGoogleUrl(),
        linkedin: this.getLinkedinUrl(),
        microsoft: this.getMicrosoftUrl(),
      };

      if (availableJumps[this.$route.query.jumpto]) {
        window.location.href = availableJumps[this.$route.query.jumpto];
      }
    },
    async mounted() {
      const sources = {
        gl: this.handleGoogleConnect,
        lk: this.handleLinkedinConnect,
        ms: this.handleMicrosoftConnect,
      };

      const callbackSocialSource = this.$route.query.s;
      if (callbackSocialSource && sources[callbackSocialSource]) {
        try {
          await sources[callbackSocialSource]();
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      }
    },
    computed: {
      input: {
        get() {
          return this.email;
        },
        set(value) {
          this.$emit('update:email', value);
        },
      },
      text() {
        return this.isError
          ? this.$i18n.t('login.no-account-associated')
          : this.$i18n.t('login.log-in-to-your-workspace');
      },
    },
    methods: {
      custom({ flags }) {
        if (flags.touched) {
          return {
            on: ['input', 'change', 'blur'],
          };
        }

        return { on: ['change', 'blur'] };
      },
      async getToken() {
        try {
          this.$emit('is-fetching', true);

          const validator = await this.$refs.observer.validate();

          if (validator) {
            const { data } = await auth.sendTokenMagicLinkEmail(this.email);

            this.$emit('user-fetched', data);
          }

          this.$emit('is-fetching', false);
        } catch (error) {
          if (error?.response?.status === 404) {
            this.emailSet = this.email;

            this.isError = true;
          } else {
            this.$toast.show({
              type: 'error',
              message: error?.response?.data?.message || error,
            });
          }

          this.$emit('is-fetching', false);
        }
      },
      getGoogleUrl() {
        return auth.googleConnectPortal(this.googleRedirectURI);
      },
      getLinkedinUrl() {
        return auth.linkedinConnectPortal(this.linkedinRedirectURI);
      },
      getMicrosoftUrl() {
        return auth.microsoftConnectPortal(this.microsoftRedirectURI);
      },
      async handleSocialConnect(media, tokenOrCode, redirectURI) {
        try {
          const { data } = await auth.performSocialConnect(media, tokenOrCode, redirectURI);
          if (data.token) {
            this.$router.push({
              path: '/auth',
              query: {
                token: data.token,
                ...this.$route.query.next && { next: this.$route.query.next },
              },
            });
          } else {
            this.$router.push({
              path: '/auth/login'
            });
            this.$toast.show({
              type: 'error',
              message: 'Aucun compte n\'a été trouvé avec cette adresse email',
            });
          }
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error?.response?.data?.message || error,
          });
        }
      },
      async handleGoogleConnect() {
        const tokenKey = 'access_token=';
        const unTrimedToken = location.href.substring(location.href.indexOf(tokenKey));
        const token = unTrimedToken.substring(tokenKey.length, unTrimedToken.indexOf('&'));
        await this.handleSocialConnect(
          'google',
          token,
          this.googleRedirectURI,
        );
      },
      async handleLinkedinConnect() {
        await this.handleSocialConnect(
          'linkedin',
          this.$route.query.code,
          this.linkedinRedirectURI,
        );
      },
      async handleMicrosoftConnect() {
        const tokenKey = 'code=';
        const unTrimedToken = location.href.substring(location.href.indexOf(tokenKey));
        const token = unTrimedToken.substring(tokenKey.length, unTrimedToken.indexOf('&'));
        await this.handleSocialConnect(
          'microsoft',
          token,
          this.microsoftRedirectURI,
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .auth-magic-login {
    &__container {
      width: 100%;
      flex-grow: 1;
      padding: 80px 0 50px;
      max-width: 400px;
    }

    &__title {
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: $color-tertiary;
    }

    &__button {
      width: 100%;
      background: $color-blue-gradient-2;
    }

    &__signup {
      text-align: center;
      margin-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      a {
        width: 100%;
        color: $color-primary;
      }
    }

    &__footer {
      padding-bottom: 50px;
      justify-content: center;
      width: 100%;
      max-width: 308px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $color-tertiary;
    }
  }

  .social_connect_button {
    width: 100%;
    border-radius: 25px;
    margin-bottom: 15px;

    &__container {
      width: 100%;
      flex-grow: 1;
      padding: 80px 0 50px;
      max-width: 400px;
    }

    &__button {
      width: 100%;
    }

  }

  .separator-container {
    margin: 10% 0%;
    display: flex;
    align-items: center;

    & > p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: $color-tertiary;
      margin: 0 3%;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $color-blue-medium;
    opacity: 0.5;
    padding-right: 10px;
    padding-left: 10px;
  }

  .social-button-block {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    margin-top: 10%;
  }

  a.social-auth {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    & > img {
      width: 18px;
      height: 18px;
    }
  }
</style>
