<script lang="ts" setup>
import { computed, ref } from 'vue';
import CampaignsCardType from '@/components/Campaigns/CampaignsCard/CampaignsCardType.vue';
import { useI18n } from '@/i18n/i18n';
import { Campaign } from '@/domains/models/Campaign';
import { useCampaignsStore } from '@/store/pinia/campaigns';
import BcRadio from '@/ui-kit/components/BcRadio/BcRadio.vue';

const props = defineProps<{
  cardContent: Campaign
  isSelected: Boolean
}>();

const { t } = useI18n();

defineEmits(['checked']);

const campaignStore = useCampaignsStore();

const isHover = ref(false);

const type = computed(() => props.cardContent.campaignTypes?.[0]?.name || campaignStore.configuration.types.find(type => type._id === props.cardContent?._campaignTypes?.[0])?.name);

</script>

<template>
  <div class="campaigns-card w-full flex-col border-radius-m is-relative px-[30px] py-[20px]"
    @mouseenter="isHover = true" @mouseleave="isHover = false">
    <div
      class="campaigns-card__container grid grid-cols-[18px_minmax(100px,12fr)_minmax(100px,10fr)_minmax(100px,10fr)] relative flex gap-x-5 w-full items-center justify-start">
      <div class="campaigns-card__wrapper justify-content-left" @click.prevent>
        <bc-radio :id="cardContent._id" :name="cardContent._id" :checked="isSelected" :disabled="false" class="w-[18px] h-[18px]" />
      </div>

      <!-- Name and Title -->
      <div class="is-secondary-dark is-align-items-center w-full flex items-center">
        <p class="campaigns-card__text body-l--medium is-ellipsis w-full">
          {{ cardContent.name }}
        </p>
      </div>

      <div class="campaigns-card__wrapper w-full flex shrink-0 justify-start">
        <CampaignsCardType :type="type" />
      </div>

      <div class="campaigns-card__wrapper w-full flex shrink-0 justify-start">
        {{ cardContent.prospectsCount }}
      </div>
    </div>
  </div>
</template>
