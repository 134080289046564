<script setup>
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { useStore } from '@/store.js';

  // Props
  defineProps({
    isChecked: {
      type: Boolean,
      default: false,
    },
    showArrivalDate: {
      type: Boolean,
      default: false,
    },
  });

  // Store
  const store = useStore();

  // Methods
  const handleSort = async(field, sortOption) => {
    await store.dispatch('setSort', {
      field,
      direction: sortOption.value,
    });
  };
</script>

<template>
  <div class="campaigns-grid">
    <div class="campaigns-grid__container grid grid-cols-[18px_minmax(100px,12fr)_minmax(100px,10fr)_minmax(100px,10fr)] gap-x-5 w-full items-center text-xs">
      <p/>
      <p
        class="campaigns-grid__text is-uppercase">
        {{ $t('campaigns.name') }}
      </p>
      <p
        class="campaigns-grid__text is-uppercase">
        type
      </p>
      <p class="campaigns-grid__text is-uppercase w-full shrink-0">
        {{ $t('campaigns.prospects-number') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .campaigns-grid {
    padding: 25px 30px 10px 30px;

    &__checkbox {
      background: $color-white;
    }

    &__text {
      color: $color-blue-heavy-dark;
    }
  }
</style>
