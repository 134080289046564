<script lang="ts" setup>
  import { Candidate } from '@/domains/models/Candidate';
  import type { Ref } from 'vue';
  import { computed, defineEmits, defineProps, ref, set as $set, watch, withDefaults, onMounted } from 'vue';
  import PanelCoderTop from '@/components/Panel/PanelCoder/PanelCoderTop.vue';
  import PanelCoderContacts from '@/components/Panel/PanelCoder/PanelCoderContacts.vue';
  import PanelCoderDetails from '@/components/Panel/PanelCoder/PanelCoderDetails.vue';
  import MvAccordion from '@/ui-kit/components/MvAccordion/MvAccordion.vue';
  import MvAccordionTab from '@/ui-kit/components/MvAccordionTab/MvAccordionTab.vue';
  import PanelCoderCompany from '@/components/Panel/PanelCoder/PanelCoderCompany.vue';
  import { useStore } from '@/store';
  import { useProcessesStore } from '@/store/pinia/processes';
  import PanelCoderProcess from '@/components/Panel/PanelCoder/PanelCoderProcess.vue';
  import { useQuery } from '@tanstack/vue-query';
  import { useRoute, useRouter } from 'vue-router/composables';
  import documentsController from '@/managers/documents/controller';
  import PanelCoderDocuments from '@/components/Panel/PanelCoder/PanelCoderDocuments.vue';
  import IntegrationKasprButton from '@/components/Integration/IntegrationKasprButton.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { RouterLink } from 'vue-router';
  import ProcessDropdown from '@/components/Process/ProcessDropdown.vue';
  import { useI18n } from '@/i18n/i18n';
  import { useElementBounding, useScroll } from '@vueuse/core';
  import { useProcessesApi } from '@/composables/api/useProcessesApi';
  import { useProcessesFilter } from '@/composables/useProcessesFilter';
  import ViewsTabs from '@/components/Views/ViewsTabs';
  import PanelCoderExperiences from '@/components/Panel/PanelCoder/PanelCoderExperiences.vue';
  import PanelCoderSchooling from '@/components/Panel/PanelCoder/PanelCoderSchooling.vue';

  const props = withDefaults(defineProps<{
    profile: Candidate
  }>(), {
    profile: () => ({}) as Candidate,
  });
  const emits = defineEmits<{
    (e: 'update-candidate', value: Candidate): void,
  }>();
  const processesStore = useProcessesStore();
  const route = useRoute();
  const router = useRouter();
  const processesApi = useProcessesApi();
  const { processes } = processesApi;
  const {
    processesCount,
    filteredProcesses,
    statusFilterOptions,
    processStatusFilter,
  } = useProcessesFilter();
  const { t } = useI18n();

  const coderId = computed(() => route.query.coderId || route.params.id);
  const isContact = computed(() => {
    if (!props.profile?.profileTypes) return false;
    return props.profile.profileTypes.includes('contact') && !props.profile.profileTypes.includes('candidate');
  })

  const viewTabs = computed(() => {
    const tabs = [
      {
        key: 'profile-documents',
        icon: 'companies',
        label: t('generics.profile-document'),
        onClick: () => {
          activeViewTab.value = 'profile-documents';
          router.replace({
            query: {
              ...route.query,
              'view-tab': 'profile-documents',
            },
          }).catch(() => {});
        },
      },
      {
        key: 'experiences',
        icon: 'file-cv',
        label: t('generics.experiences-and-schooling'),
        onClick: () => {
          activeViewTab.value = 'experiences';
          router.replace({
            query: {
              ...route.query,
              'view-tab': 'experiences',
            },
          }).catch(() => {});
        },
      },
    ];
    return tabs
  });


  const viewTabsKeys = viewTabs.value.map(tab => tab.key);
  const activeViewTab = ref(viewTabsKeys.includes(route.query['view-tab'] || '') ?  route.query['view-tab'] : viewTabs.value[0].key);

  const activeTabs = ref<number[]>([]);

  const store = useStore();
  const organization = store.state.user?.profile?._organization;

  const accordion = ref<HTMLDivElement | null>(null);
  const accordionBoundary = useElementBounding(accordion);

  const container = ref<HTMLDivElement | null>(null);
  const { y: containerScroll } = useScroll(container);

  const documentsRef = ref<HTMLDivElement | null>(null);

  const tabs = {
    contacts: 'contacts',
    details: 'details',
    company: 'company',
    processes: 'processes',
    documents: 'documents',
  } as const;

  const candidateTabsOrder = [tabs.contacts, tabs.details, tabs.company, tabs.processes, tabs.documents];
  const contactTabsAndOrder = [tabs.contacts, tabs.company, tabs.details];
  const currentTabsAndOrder = computed(() => props.profile.profileTypes?.includes('candidate') ? [...candidateTabsOrder] : [...contactTabsAndOrder]);

  const experiencesCount = computed(() => (props.profile?.lastKnownExperiences?.experiences?.length ?? 0));
  const schoolingCount = computed(() => (props.profile?.schooling?.length ?? 0));

  function toOrderClass(index: number) {
    return `order-${index + 1}`;
  }

  const tabsCssOrderObject = computed(() => currentTabsAndOrder.value.reduce((acc, tab, index) => ({
    ...acc,
    [tab]: toOrderClass(index),
  }), {}));

  const isCreatingProcess = ref(false);
  const isEditingContacts: Ref<boolean> = ref(false);
  const isEditingDetails: Ref<boolean> = ref(false);
  const isEditingCompany: Ref<boolean> = ref(false);

  const isKasprEnabled = !!organization?.configuration?.integrations?.kaspr?.credentials?.apiKey;

  const contactsCount = computed(() => {
    return [...props.profile?.email ? [props.profile?.email] : [], ...(props.profile?.emails || [])]?.length + [...props.profile?.phone ? [props.profile?.phone] : [], ...(props.profile?.phones || [])]?.length + [...props.profile?.social?.linkedin ? [props.profile?.social?.linkedin] : []].length;
  });

  const candidateDetails = computed(() => [
    ...props.profile.locations || [],
    ...props.profile._professions || [],
    ...props.profile.salaryWanted || [],
    ...props.profile.currentSalary || [],
    ...[...props.profile._technos1 || [], ...props.profile._technos2 || [], ...props.profile._technos3 || []],
    ...props.profile.contractCurrent && props.profile.contractCurrent > 0 ? [props.profile.contractCurrent] : [],
  ]);
  const contactDetails = computed(() => [
    ...props.profile.locations || [],
    ...props.profile._professions || [],
  ]);
  const detailsCount = computed(
    () =>
      (props.profile.profileTypes?.includes('candidate')
        ? candidateDetails.value?.length
        : contactDetails.value?.length) || 0,
  );

  const documentsCount = computed(() => {
    return props.profile?._documents?.length || 0;
  });
  const uploadDocument = ref<HTMLInputElement | null>(null);
  const triggerUploadButton = () => {
    uploadDocument.value?.click();
  };
  const addDocument = async(e) => {
    if (e.target.files[0]) {
      const { data } = await documentsController.createCoderDocument({
        id: coderId.value,
        file: e.target.files[0],
      });

      emits('update-candidate', { _documents: data._documents });
    }
  };

  const { isLoading: isProcessesLoading, refetch: refetchProcesses } = useQuery({
    queryKey: ['PANEL_CODER_GET_PROCESSES', coderId],
    queryFn: async() => {
      try {
        const processes = await processesApi.getProcessesByCandidateId({ id: coderId.value });
        activeTabs.value.push(3);

        return processes ?? [];
      } catch (e) {
        console.error(e);
      }
    },
  });
  const updateProcess = ({ index, process }) => {
    const processIndex = processes.value.findIndex(p => p._id === filteredProcesses.value[index]._id);
    $set(processes.value, processIndex, { ...processes.value[processIndex], ...process });
  };
  const removeProcessFromProcesses: ({ id }: { id: string }) => void = ({ id }) => {
    processes.value = processes.value.filter(process => process._id !== id);
  };
  const handleUpdateCandidate = async (eventData) => {
    // Call refetch to reload the processes data
    if (route.query.processId && typeof route.query.processId === 'string') {
      processesStore.updateCardInfo({ processId : route.query.processId, email: eventData.email , emails : eventData.emails , phone : eventData.phone , phones : eventData.phones });
    }
    await refetchProcesses();
    emits('update-candidate', eventData);
  };
  const defaultOption = {
    name: t('processes.all-processes'),
    key: undefined,
    categoryKey: 'all',
  };
  processStatusFilter.value = defaultOption;

  const employeesCount = computed(() => {
    return props.profile?._employees?.filter(e => e.active && e._company)?.length || 0;
  });

  onMounted(() => {
    if (route.query.category === 'documents') {
      setTimeout(() => {
        documentsRef.value?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  });

  watch(() => props.profile?._id, () => {
    const initialActiveTabs = [
      contactsCount.value,
      detailsCount.value,
      employeesCount.value,
      processesCount.value,
      documentsCount.value,
      experiencesCount.value,
      schoolingCount.value,
    ];
    activeTabs.value = initialActiveTabs.map((_, index) => index).filter((index) => initialActiveTabs[index] > 0);
  }, { immediate: true });
</script>

<template>
  <div
    ref="container"
    class="flex max-h-full min-h-full flex-col overflow-auto bg-white pb-5">
    <PanelCoderTop
      :candidate="profile"
      @update-candidate="$emit('update-candidate', $event)"/>

    <div class="mt--4">
      <ViewsTabs :tabs="viewTabs" :forRoute="false" :active="activeViewTab" />
    </div>

    <MvAccordion
      :key="profile._id"
      ref="accordion"
      :active-index.sync="activeTabs"
      class="mt-4 flex flex-col gap-2.5 px-[20px]"
      multiple
    >
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.contacts)"
        v-show="activeViewTab === viewTabs[0].key"
        :class="tabsCssOrderObject.contacts"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('panel-coder-edit.contact-information') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ contactsCount }}
            </div>
            <div
              v-show="!isEditingContacts"
              class="absolute right-0 flex items-center gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <IntegrationKasprButton
                v-if="isKasprEnabled"
                :profile="profile"
                class="flex !size-[30px] items-center justify-center"
                ressource="coder"
                @profile-updated="$emit('update-candidate', $event)"
              >
              </IntegrationKasprButton>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingContacts = true;
                  activeTabs.push(0);
                }"
              >
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCoderContacts
          :candidate="profile"
          :is-editing="isEditingContacts"
          @close-edit="isEditingContacts = false"
          @update-candidate="handleUpdateCandidate($event)"
        />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.details)"
        v-show="activeViewTab === viewTabs[0].key"
        :class="tabsCssOrderObject.details"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.details') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ detailsCount }}
            </div>
            <div
              v-show="!isEditingDetails"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingDetails = true;
                  activeTabs.push(1);
                }"
              >
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCoderDetails
          :candidate="profile"
          :is-editing="isEditingDetails"
          @close-edit="isEditingDetails = false"
          @update-candidate="$emit('update-candidate', $event)"
        />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.company)"
        v-show="activeViewTab === viewTabs[0].key"
        :class="tabsCssOrderObject.company"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.associated-company') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ employeesCount }}
            </div>
            <div
              v-show="!isEditingCompany"
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isEditingCompany = true;
                  activeTabs.push(2);
                }"
              >
                <i class="icon-edit-3 text-2xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCoderCompany
          :candidate="profile"
          :is-editing="isEditingCompany"
          @close-edit="isEditingCompany = false"
          @update-candidate="$emit('update-candidate', $event)"
        />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.processes)"
        v-show="activeViewTab === viewTabs[0]?.key"
        :class="tabsCssOrderObject.processes"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Process
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ processesCount }}
            </div>
            <div
              class="absolute right-0 flex items-center gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <ProcessDropdown
                :active-tabs="activeTabs"
                :items="statusFilterOptions"
                :parent-height="accordionBoundary.height"
                :scroll-position="containerScroll"
                :value="processStatusFilter"
                class="min-w-[160px]"
                @select="processStatusFilter = $event"
              >
                <template #icon>
                  <span class="icon-equalizer mr-2 text-blue-800"></span>
                </template>
              </ProcessDropdown>
              <RouterLink
                :to="{
                  name: $route.name,
                  params: {
                    id: profile._id,
                  },
                  query: {
                    ...route.query,
                    type: 'process-kanban',
                    subtype: 'coders',
                  },
                }">
                <BcButton
                  class="!h-[32px] !text-sm !font-medium"
                  color="success"
                  icon-left="kanban"
                  size="extra-small"
                >
                  {{ $t('generics.kanban') }}
                </BcButton>
              </RouterLink>
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="() => {
                  isCreatingProcess = true;
                  activeTabs.push(3);
                }"
              >
                <i class="icon-plus-circle text-3xl"/>
              </button>
            </div>
          </div>
        </template>
        <PanelCoderProcess
          v-if="activeViewTab === viewTabs[0]?.key"
          :candidate="profile"
          :is-editing="isCreatingProcess"
          :processes="filteredProcesses"
          class="mt-2"
          @close-edit="isCreatingProcess = false"
          @create-process="() => {
            refetchProcesses();
            isCreatingProcess = false;
          }"
          @update-process="updateProcess"
          @remove-process="removeProcessFromProcesses"
        />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="currentTabsAndOrder.includes(tabs.documents)"
        v-show="activeViewTab === viewTabs[0].key"
        :class="[
          { 'panel-coder-profile__documents': documentsCount > 0 },
          tabsCssOrderObject.documents,
        ]"
      >

        <template #header>
          <div ref="documentsRef" class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              Documents
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ documentsCount }}
            </div>
            <div
              class="absolute right-0 flex gap-2.5 bg-white pl-2.5"
              @click.stop
            >
              <button
                class="text-md flex size-6 items-center justify-center bg-white text-blue-400 hover:border-blue-500 hover:text-blue-500"
                @click.stop="triggerUploadButton"
              >
                <i class="icon-plus-circle text-3xl"/>
              </button>
              <input
                ref="uploadDocument"
                accept=".doc,.docx,application/pdf"
                class="hidden"
                name="document"
                type="file"
                @change="addDocument"
              />
            </div>
          </div>
        </template>
        <PanelCoderDocuments
          :candidate="profile"
          @update-candidate="$emit('update-candidate', $event)"
        />
      </MvAccordionTab>

      <MvAccordionTab
        v-if="activeViewTab === viewTabs[1].key"
        :class="tabsCssOrderObject.experiences"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.experiences') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ experiencesCount }}
            </div>
          </div>
        </template>
        <PanelCoderExperiences :candidate="profile" />
      </MvAccordionTab>
      <MvAccordionTab
        v-if="activeViewTab === viewTabs[1].key"
        :class="tabsCssOrderObject.schooling"
      >
        <template #header>
          <div class="flex shrink-0 items-center py-2.5">
            <b class="font-bold">
              {{ $t('generics.schooling') }}
            </b>
            <div
              class="ml-1 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-blue-500 p-[5px] text-xs font-normal text-white"
            >
              {{ schoolingCount }}
            </div>
          </div>
        </template>
        <PanelCoderSchooling :candidate="profile" />
      </MvAccordionTab>
    </MvAccordion>
  </div>
</template>

<style lang="scss">
  .panel-coder-profile__documents > .p-toggleable-content {
    min-height: 640px;
  }
</style>
