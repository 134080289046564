<script lang="ts" setup>
import { Candidate } from '@/domains/models/Candidate';
import { defineProps, computed } from 'vue';
import PanelCoderSchoolingsItem from './PanelCoderExperiences/PanelCoderSchoolingsItem.vue';
interface Props {
  candidate: Candidate;
}

const props = defineProps<Props>();
const schoolings = computed(() => props.candidate.schooling);
</script>

<template>
  <div class="flex flex-col gap-2 w-full">
    <PanelCoderSchoolingsItem v-for="schooling in schoolings" :key="schooling._id" :schooling="schooling" />
  </div>
</template>
1``