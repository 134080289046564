<script lang="ts" setup>
import PanelHeaderNav from '@/components/Panel/PanelHeader/PanelHeaderNav.vue';
import { usePanelStore } from '@/store/pinia/panel';
import { computed, ref } from 'vue';
import { useI18n } from '@/i18n/i18n';
import { Campaign } from '@/domains/models/Campaign';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { addCodersToCampaign, getCampaigns } from '@/api/campaigns';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import BcRadio from '@/ui-kit/components/BcRadio/BcRadio.vue';
import { useToast } from '@/ui-kit/components/BcToast';
import CampaignsAddToCampaignCard from '@/components/Campaigns/CampaignsAddToCampaignCard.vue';
import CampaignsAddToCampaignGrid from '@/components/Campaigns/CampaignsAddToCampaignGrid.vue';
import { useStore } from '@/store';

const { t } = useI18n();
const panelStore = usePanelStore();
const toast = useToast();
const store = useStore();

const props = defineProps<{ selectedCoders: any[] }>();

function onClose() {
  panelStore.closeModal();
}

const { data: campaigns, isLoading } = useQuery({
  queryKey: ['campaigns'],
  queryFn: async () => {
    const response = await getCampaigns({});
    return response.data;
  },
});

const selectedCampaignId = ref<string | null>(null);

function handleSelectCampaign(campaignId: string) {
  selectedCampaignId.value = campaignId;
}

const selectedCampaign = computed(() => {
  if (!selectedCampaignId.value || !campaigns?.value) return null;
  return campaigns.value.find(campaign => campaign._id === selectedCampaignId.value);
});

async function handleAddToCampaign() {
  if (!selectedCampaign.value) {
    toast.show({
      title: t('generics.error'),
      message: t('campaigns.select-campaign-to-add'),
      type: 'error',
      icon: 'cross',
    });
    return;
  }

  try {
    await addCodersToCampaign(selectedCampaign.value._id, props.selectedCoders.map(coder => coder?._id || coder));
    toast.show({
      title: t('generics.success'),
      message: t('campaigns.successfully-added-to-campaign'),
      type: 'success',
      icon: 'check',
    });
    store.dispatch('setSelectedCards', []);
    panelStore.closeModal();
  } catch (error: any) {
    toast.show({
      title: t('generics.error'),
      message: error?.response?.data?.message || t('campaigns.error-adding-to-campaign'),
      type: 'error',
      icon: 'cross',
    });
  }
}
</script>

<template>
  <div class="flex w-[640px] max-w-[100vw] flex-col">
    <div
      class="flex min-h-[50px] w-full max-w-[100vw] items-center gap-6 border-b border-b-[#EDF0FF] bg-neutral-200 px-[18px] py-2">
      <PanelHeaderNav :is-back-visible="false" :on-close="onClose" class="flex shrink-0" />
      <h2 class="text-xl font-bold first-letter:capitalize">
        {{ $t('campaigns.add-to-a-campaign') }}
      </h2>
    </div>
    <div class="flex max-h-full grow flex-col overflow-auto px-5">
      <CampaignsAddToCampaignGrid />
      <div v-if="isLoading" class="flex justify-center py-4">
        <BcSpinner size="medium" />
      </div>
      <div v-else-if="campaigns?.length" class="flex flex-col gap-[5px]">
        <div v-for="campaign in campaigns" :key="campaign._id"
          class="flex cursor-pointer items-center justify-between rounded border bg-neutral-100 border-[#E1E4ED] h-[58px]"
          @click="handleSelectCampaign(campaign._id)">
          <CampaignsAddToCampaignCard :card-content="campaign" :is-selected="selectedCampaignId === campaign._id" />
          <!-- <div class="flex items-center gap-4">
          
            <BcRadio
              :model-value="selectedCampaignId === campaign._id"
              :name="campaign._id"
              :id="campaign._id"
              @update:model-value="handleSelectCampaign(campaign._id)"
            />
            <div class="flex flex-col">
              <span class="font-bold">{{ campaign.name }}</span>
              <div class="flex items-center gap-2 text-sm text-gray-600">
                <span>{{ campaign.campaignTypes?.[0]?.name }}</span>
                <span class="text-gray-400">•</span>
                <span>{{ campaign.prospectsSources?.length || 0 }} {{ $t('campaigns.prospects') }}</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div v-else class="text-center text-gray-600 py-4">
        {{ $t('campaigns.no-available-campaigns') }}
      </div>
    </div>
    <div class="flex justify-end gap-2 p-4 border-t border-[#EDF0FF]">
      <BcButton type="outlined" @click="onClose">
        {{ $t('generics.cancel') }}
      </BcButton>
      <BcButton :disabled="!selectedCampaignId" @click="handleAddToCampaign">
        {{ $t('campaigns.add-to-campaign') }}
      </BcButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.bc-radio) {
  @apply flex-shrink-0;
}
</style>
