<template>
  <div class="panel-coder-notes is-full-width is-flex flex-col">
    <div class="w-full px-5 pt-5">
      <TabSwitcher
        :active-tab="activeTab"
        :tabs="timelineTabs"
        class="w-full justify-evenly"
        @set-active-tab="setTimelineType"
      />
    </div>
    <PanelCoderProspectsTimeline
      v-if="isProspectsTimeline"
      :coder="profile" class="px-5 py-[20px]"/>
    <panel-view-timeline
      v-else
      :profile="profile"
      user-type="coders"
      @qualification-created="handleQualificationCreated"
      @profile-updated="$emit('profile-updated', $event)">
    </panel-view-timeline>
  </div>
</template>

<script lang="ts" setup>
  import PanelViewTimeline from '@/components/Panel/PanelView/PanelViewTimeline.vue';
  import TabSwitcher from '@/components/UIKit/TabSwitcher.vue';
  import { Candidate } from '@/domains/models/Candidate';
  import { useRoute, useRouter } from 'vue-router/composables';
  import { TimelineType } from '@/domains/models/Timeline';
  import macros from '@/macros/macros';
  import { computed, onBeforeMount, onUnmounted } from 'vue';
  import { useI18n } from '@/i18n/i18n';
  import { useQuery } from '@tanstack/vue-query';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { getUserRemindersCount } from '@/api/timeline';
  import PanelCoderProspectsTimeline
    from '@/components/Panel/PanelCoder/PanelCoderTemp/PanelCoderProspectsTimeline.vue';
  import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';

  type Props = {
    profile?: Candidate;
  }

  const props = defineProps<Props>();
  const router = useRouter();
  const route = useRoute();
  const toast = useToast();
  const { t, tc } = useI18n();
  const launchDarklyStore = useLaunchDarklyStore();

  const emits = defineEmits<{
    (e: 'profile-updated', value: Candidate): void,
  }>();

  const isCampaignsVisible = computed(() => {
  return launchDarklyStore.getFlag('enableCampaigns') ?? false;
});

  const timelineTabs = computed<{
    value: TimelineType,
    label: string,
  }[]>(() => [
      ...Object.keys(macros.timeline.types).map((type) => ({
        value: type as TimelineType,
        label: t(`timeline.${type}`),
        ...type === macros.timeline.types.reminders && { count },
      })),
      ...isCampaignsVisible.value ? [{
        value: 'prospects',
        label: tc('campaigns.campaigns', 2),
        // count: 2,
      }] : [],
    ],
  );

  //TEMP
  const isProspectsTimeline = computed(() => route.query?.['timeline-type'] === 'prospects');

  const activeTab = computed<number>(() => {
    const type = route.query['timeline-type'] as TimelineType;
    const index = timelineTabs.value.findIndex((tab) => tab.value === type);

    return index > -1 ? index : 0;
  });

  const setTimelineType = (typeIndex: number): void => {
    const type = timelineTabs.value[typeIndex].value;

    router.replace({
      ...route,
      name: route.name || undefined,
      path: route.path,
      query: {
        ...route.query,
        'timeline-type': type,
      },
    });
  };
  const handleQualificationCreated = (event: { _coder: Candidate }) => {
    emits('profile-updated', event._coder);
  };

  const { data: count } = useQuery({
    queryKey: ['GET_REMINDERS_COUNT', route.params.id ?? props.profile?._id],
    queryFn: async() => {
      try {
        if (!props.profile?._id && ['new-coder', 'new-contact'].includes(route.params.id)) {
          return 0;
        }

        const { data } = await getUserRemindersCount({
          userType: 'coders',
          id: route.params.id ?? props.profile?._id,
        });

        return data.count ?? 0;
      } catch (e) {
        toast.show({
          message: t('timeline.error-loading-reminders-count'),
          type: 'error',
        });

        return 0;
      }
    },
  });

  onUnmounted(() => {
    delete route.query['timeline-type'];
  });

  onBeforeMount(() => {
    setTimelineType(0);
  });
</script>
