<template>
  <timeline-item-wrapper
    :action="{ icon: formattedAction.icon || 'phone', source: formattedAction.source }">
    <div
      ref="container"
      class="qualifications-item border-radius-m is-full-width is-relative is-column">
      <div class="qualifications-item__header flex items-center gap-2 p-1.5">
        <bc-avatar
          v-if="coach"
          :placeholder="userInitials"
          :src="coach.pictureUrl"
          alt="coach"
          class="qualifications-item__picture shrink-0"
          size="s">
        </bc-avatar>
        <div class="flex items-center">
          <component
            :is="transformedHeader"
            v-if="formattedAction.header">
          </component>
        </div>
        <div
          v-tooltip="qualificationTooltip"
          class="qualifications-item__date font-size-xs">
          {{ qualificationFromNow }}
        </div>
        <NotesOptionsDropdown
          :is-disabled="coach._id !== userProfile._id"
          :is-pin="true"
          @delete="goDeleteNote"
          @edit="goEditNote"
          @unpin="unpinNote"
        />
      </div>

      <div class="qualifications-item__content gap-3">
        <div
          v-show="!images.length || formattedContent !== '-' || isEditing"
          class="is-column is-full-width relative gap-2 p-[20px]">
          <button
            v-if="isEditing"
            class="icon-cross absolute right-4 top-4 z-10 p-1 text-xs text-blue-800 hover:text-blue-500"
            @click="cancelEdit">
          </button>
          <div
            :class="{ 'qualifications-item__container--expanded': isExpanded || isEditing }"
            class="qualifications-item__container is-full-width"
          >
            <bc-rich-text-editor-bubble
              :key="`${qualification._id}-${randomKey}`"
              ref="textarea"
              v-model="formattedContent"
              :is-editable="isEditing"
              position="absolute"
              class="font-size-s qualifications-item__editor"
              @blur="setIsTyping()"
              @focus="setIsTyping(true)"
            >
            </bc-rich-text-editor-bubble>
          </div>
          <a
            v-show="canSeeMore && !isExpanded && !isEditing"
            class="qualifications-item__see-more is-underline font-size-s"
            @click="isExpanded = true"
          >
            {{ $t('companies.panel-view-timeline.see-more') }}
          </a>
        </div>
        <NotesImages
          :images="images"
          :is-read-only="!isEditing"
          class="qualifications-item__footer px-[20px]"
          @remove-image="handleRemoveImage"
        />
        <qualification-item-coders-view
          v-if="isCandidate && !isEditing"
          :open-to-work="openToWork"
          :rating="rating"
          class="qualifications-item__footer px-[20px]">
        </qualification-item-coders-view>
        <qualification-item-coders-edit
          v-if="isCandidate && isEditing && isEditable"
          :open-to-work="openToWork"
          :rating="rating"
          class="qualifications-item__footer px-[20px]"
          @update:rating="rating = $event"
          @update:open-to-work="openToWork = $event"
        >
        </qualification-item-coders-edit>
        <NotesEditorFooter
          v-if="isEditing"
          :can-pin="true"
          :container-size="containerSize"
          :images="images"
          :is-valid="isValid"
          :pin="pin"
          :templates="templates"
          :tooltip-content="tooltipContent"
          :user-profile="user"
          class="px-[20px] pb-[20px]"
          @select-template="handleSelectTemplate"
          @send-note="editNote"
          @add-image="handleAddImage"
          @update:pin="pin = $event"
        />
      </div>

      <ModalConfirm
        :cancel-text="$t('generics.cancel')"
        :confirm-text="$t('generics.yes-delete')"
        :content="`
        ${$t('timeline.by-deleting-this-note-you-will-lose-it-permanently')}.\n\n
        ${$t('timeline.this-action-is-irreversible')}`"
        :is-open="isModalOpen"
        :title="$t('timeline.delete-this-note')"
        color="error"
        icon="❌"
        @close="isModalOpen = false"
        @on-confirm="handleDeleteNote"
      />
      <ModalConfirm
        :cancel-text="$t('generics.no-cancel-this-action')"
        :confirm-text="$t('generics.yes')"
        :content="$t('templates.your-entire-input-will-be-deleted')"
        :is-open="isTemplateModalOpen"
        :title="$t('templates.do-you-want-to-use-this-template')"
        color="success"
        icon="📝"
        @close="handleCloseTemplateModal"
        @on-confirm="handleConfirmTemplate">
      </ModalConfirm>
    </div>
  </timeline-item-wrapper>

</template>

<script>
  import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar';
  import BcRichTextEditorBubble from '@/ui-kit/components/BcRichTextEditor/BcRichTextEditorBubble';
  import { noteController } from '@/managers/notes/controller';
  import notes from '@/common-old/macros/notes';
  import NotesEditorMixin from '@/mixins/Notes/notes';
  import { getTypeNameFromApiValue } from '@/managers/notes/formatters/noteDeserializers';
  import { apiRoles } from '@/macros/coaches/roles';
  import { mapState } from 'vuex';
  import QualificationItemCodersView
    from '@/components/Qualifications/QualificationsItem/QualificationItemCodersView';
  import QualificationItemCodersEdit
    from '@/components/Qualifications/QualificationsItem/QualificationItemCodersEdit';
  import TimelineItemWrapper from '@/components/Timeline/TimelineItemWrapper';
  import { getInitials } from '@/helpers/getIntials';
  import { format, formatDistanceToNow, parseISO } from 'date-fns';
  import { enUS, fr } from 'date-fns/locale';
  import NotesOptionsDropdown from '@/components/Notes/NotesOptionsDropdown.vue';
  import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
  import { useElementSize } from '@vueuse/core';
  import NotesImages from '@/components/Notes/NotesImages.vue';
  import NotesEditorFooter from '@/components/Notes/NotesEditor/NotesEditorFooter.vue';
  import { mapState as piniaMapState } from 'pinia';
  import { useNotesTemplatesStore } from '@/store/pinia/notesTemplates';
  import { getFormattedAction } from '@/managers/timeline/timelineCenter.js';

  const hasRightsToEditOtherQualifications = [
    apiRoles.businessManager,
    apiRoles.mentor,
    apiRoles.lead,
  ];

  export default {
    name: 'qualifications-item',
    components: {
      NotesEditorFooter,
      NotesImages,
      ModalConfirm,
      NotesOptionsDropdown,
      QualificationItemCodersEdit,
      QualificationItemCodersView,
      BcRichTextEditorBubble,
      BcAvatar,
      TimelineItemWrapper,
    },
    mixins: [NotesEditorMixin],
    props: {
      qualification: {
        type: Object,
        default: () => ({}),
      },
      action: {
        type: Object,
        default: () => ({}),
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        content: this.qualification.content || '',
        images: this.qualification?.images || [],
        rating: this.qualification.hasOwnProperty('rating') ? this.qualification.rating : null,
        openToWork: this.qualification.hasOwnProperty('openToWork') ? this.qualification.openToWork : null,
        pin: true, // qualification so true by default
        type: this.qualification?.type ? getTypeNameFromApiValue(this.qualification) : '',
        isEditing: false,
        isModalOpen: false,
        isExpanded: false,
        randomKey: Math.random(),
        isTemplateModalOpen: false,
        awaitingSelectedTemplate: '',
      };
    },
    computed: {
      ...mapState({
        tags: state => state.tags.tags,
        userProfile: state => state.user.profile,
      }),
      ...piniaMapState(useNotesTemplatesStore, { notesTemplates: 'items' }),
      formattedAction() {
        return getFormattedAction(this.action);
      },
      transformedHeader() {
        if (!this.formattedAction.header) {
          return {};
        }

        return {
          name: 'timeline-item-header-transformed',
          template: `<p class="qualifications-item__text is-align-items-center font-size-xs">
            ${this.formattedAction.header}</p>`,
          props: this.$options.props,
        };
      },
      templates() {
        return this.notesTemplates.map(template => ({
          ...template,
          author: template._coach,
        }));
      },
      isCandidate() {
        return (this.$route.query.type === 'coders' || this.$route.query.type === 'suggestions-coders');
      },
      containerSize() {
        return useElementSize(this.$refs['container']);
      },
      userInitials() {
        return getInitials(`${this.coach.firstName} ${this.coach.lastName}`);
      },
      tagsFiltered() {
        return this.tags.filter(a1 => this.userStack.map(a2 => a2.name).indexOf(a1.name) < 0);
      },
      matches() {
        return this.tagsFiltered.filter(tag => tag.name.toLowerCase().indexOf(this.input.toLowerCase()) === 0);
      },
      containerHeight() {
        return useElementSize(this.$refs.textarea)?.height;
      },
      contentHeight() {
        return useElementSize(this.$refs.textarea?.$refs?.reference)?.height;
      },
      canSeeMore() {
        return this.containerHeight.value < this.contentHeight.value;
      },
      qualificationTooltip() {
        return {
          content: `${this.$i18n.t('companies.panel-view-timeline.the')} ${this.qualificationCreationDate}`,
        };
      },
      qualificationCreationDate() {
        return format(parseISO(this.qualification.createdAt), 'd MMMM yyyy', { locale: this.$i18n.locale === 'fr' ? fr : enUS });
      },
      qualificationFromNow() {
        return formatDistanceToNow(parseISO(this.qualification.createdAt), {
          addSuffix: true,
          locale: this.$i18n.locale === 'fr' ? fr : enUS,
        });
      },
      formattedContent: {
        get() {
          return this.formatContent(this.content);
        },
        set(value) {
          this.content = value;
        },
      },
      coach() {
        return (this.qualification || {})._coach;
      },
      name() {
        if (this.$route.query.type === 'coders' || this.$route.query.type === 'suggestions-coders') {
          return `${this.qualification._coder.firstName} ${this.qualification._coder.lastName}`;
        }

        if (this.$route.query.type === 'hunt') {
          return `${this.qualification._customJob.title}`;
        }

        return this.$route.query.type === 'companies'
          ? (this.qualification._company || {}).name
          : (this.qualification.j || {}).name;
      },
      isDifferentRating() {
        return this.rating >= 0 && this.rating !== this.qualification.rating;
      },
      user() {
        return this.$store.state.user.profile;
      },
      isQualificationAuthor() {
        return this.user._id === ((this.qualification || {})._coach || {})._id;
      },
      isAuthorPresale() {
        return this.qualification?._coach?.role === apiRoles.presales;
      },
      canEditOtherCoachesQualifications() {
        return hasRightsToEditOtherQualifications.includes(this.user.role);
      },
      isEditable() {
        return (
          this.isQualificationAuthor ||
          (this.canEditOtherCoachesQualifications && this.isAuthorPresale)
        );
      },
    },
    watch: {
      content() {
        this.saveNoteToLocalStorage();
      },
      rating() {
        this.saveNoteToLocalStorage();
      },
      'qualification.content'() {
        this.content = this.qualification.content;
      },
      'qualification.rating'() {
        this.rating = this.qualification.rating;
      },
    },
    methods: {
      handleRemoveImage(index) {
        this.images.splice(index, 1);
      },
      handleAddImage(image) {
        this.images.push(image);
      },
      async unpinNote() {
        const data = await noteController.editNote({
          id: this.qualification._id,
          note: {
            type: notes.type.qualification.name,
            pin: false,
          },
        });

        this.$emit('note-unpinned', data);
      },
      getPlainText(text) {
        return text.replace(/<[a-z]\s*\/?>|<\/[a-z]\s*\/?>/gm, '');
      },
      formatContent(text) {
        const formattedText = `<p>${text.replace(/\r?\n|\rro/g, '</p><p>')}`;

        return formattedText.replace(/^\s*<p\s*\/?>|<p\s*\/?>\s*$/g, '');
      },
      setIsEditing(value) {
        this.isEditing = value;
      },
      handleSelectTemplate(template) {
        if (this.content) {
          this.awaitingSelectedTemplate = template.body;
          this.isTemplateModalOpen = true;
        } else {
          this.content = template.body;
          ++this.randomKey;
        }
      },
      handleConfirmTemplate() {
        this.content = this.awaitingSelectedTemplate;
        ++this.randomKey;
        this.handleCloseTemplateModal();
      },
      handleCloseTemplateModal() {
        this.awaitingSelectedTemplate = '';
        this.isTemplateModalOpen = false;
      },
      goDeleteNote() {
        this.isModalOpen = true;
      },
      goEditNote() {
        this.setDefaultNoteToSavedNote();

        this.setIsEditing(true);
      },
      setIsTyping(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typing: value,
          },
        });
      },
      cancelEdit() {
        this.content = this.qualification?.content;
        this.rating = this.qualification?.rating;
        this.openToWork = this.qualification?.openToWork;
        this.pin = this.qualification?.pin;
        this.images = this.qualification?.images || [];

        this.clearSavedNote();

        this.setIsEditing(false);
      },
      async editNote() {
        try {
          const data = await noteController.editNote({
            id: this.qualification._id,
            note: {
              ...(this.type && { type: this.type }),
              ...(this.content && { content: this.content }),
              ...(this.isDifferentRating && { rating: this.rating }),
              openToWork: this.openToWork ?? undefined,
              pin: this.pin ?? undefined,
              images: this.images ?? undefined,
            },
          });

          this.clearSavedNote();

          this.$emit('note-edited', { ...data, _coach: this.coach });

          if (!this.pin) {
            this.$emit('note-unpinned', data);
          }

          this.setIsEditing(false);
        } catch (error) {
          this.$toast.show({
            message: error?.response?.data?.message || error,
            type: 'error',
            icon: 'cross',
          });
        }
      },
      setDefaultNoteToSavedNote() {
        if (localStorage.getItem(`note-${(this.qualification || {})._id}`)) {
          const { content, rating, urgency } = JSON.parse(
            localStorage.getItem(`note-${(this.qualification || {})._id}`),
          );

          this.content = content || '';
          this.rating = rating || undefined;
          this.urgency = urgency || undefined;
        }
      },
      saveNoteToLocalStorage() {
        localStorage.setItem(
          `note-${(this.qualification || {})._id}`,
          JSON.stringify({
            content: this.content,
            ...(this.rating && { rating: this.rating }),
            ...(this.urgency && { urgency: this.urgency }),
          }),
        );
      },
      clearSavedNote() {
        localStorage.removeItem(`note-${(this.qualification || {})._id}`);
      },
      handleDeleteNote() {
        try {
          noteController.remove({id : this.qualification._id, actionId :this.action._id });

          this.$emit('note-deleted', this.qualification._id);
        } catch (error) {
          this.$toast.show({
            type: 'error',
            message: error.message || error.response.data,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .qualifications-item {
    border: 1px solid $color-primary;
    background: $color-white;

    &__footer {
      &:last-of-type {
        padding-bottom: 20px;
      }
    }

    .is-column {
      section {
        padding: 0;

        .skills {
          margin-bottom: 16px;
          flex-direction: column;
          width: 100%;

          p {
            margin-bottom: 8px;
          }

          section {
            display: flex;
            flex-direction: row;
            padding: 0;
            max-width: 100%;
            flex-wrap: wrap;

            .bc-tag {
              margin-right: 8px;
              width: max-content;
              flex: 0 0 auto;
              margin-bottom: 8px;
            }
          }
        }

        .salary {
          flex-direction: row;
          margin-bottom: 16px;

          div {
            display: flex;
            flex-direction: column;
            margin-right: 8px;

            p {
              margin-bottom: 6px;
            }
          }
        }

        .additional {
          align-items: center;
          flex-direction: row;

          div:first-child {
            margin-right: 16px;
          }

          div:last-child {
            display: flex;
            align-items: center;

            p {
              margin-right: 8px;
            }

            span {
              color: #3C80F7;
              border: 1px solid #3C80F74D;
              border-radius: 5px;
              padding: 8px;
            }
          }
        }
      }
    }

    &__icon {
      color: $color-primary;
      left: -35px;
    }

    &__header {
      border-bottom: 1px solid $color-blue-medium;
    }

    &__content {
      flex-direction: column;
    }

    &__see-more {
      color: $color-primary;
    }

    &__text {
      color: $color-tertiary;

      &--blue {
        color: $color-primary;
      }

      &:deep(b) {
        font-weight: initial;
        color: $color-primary;
        cursor: pointer;
      }
    }

    &__date {
      background-color: $color-blue-light;
      color: $color-tertiary;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      padding: 5px 10px;
      height: 24px;
      margin-left: auto;
      font-size: $font-size-xs;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: normal;
    }

    &__wrapper {
      right: 15px;
      bottom: 22.5px;
    }

    &__rating {
      align-items: center;
    }

    &__container {
      max-height: calc(4 * 1.5rem);

      &--expanded {
        max-height: initial;
      }

      &:deep(.bc-rich-text-editor-bubble) {
        max-height: initial;
      }

      &:deep(.bc-rich-text-editor-bubble__container) {
        overflow: hidden;
        margin-bottom: 0;
      }

      &:deep(.bc-rich-text-editor-bubble__textarea) {
        overflow: hidden;
      }
    }

    &__editor {
      resize: none;
      min-height: 4.6em;
      color: $color-secondary-light;
      font-family: $font-family;
      overflow: hidden;

      &:deep(.bc-rich-text-editor-bubble__container) {
        overflow: hidden;
      }

      &:deep(.bc-rich-text-editor-bubble__textarea) {
        overflow: hidden;
      }

      &:deep(.bc-rich-text-editor-bubble) {
        max-height: initial;
      }
    }

    &__editor-container {
      padding: 10px;
      border-top: 1px solid $color-blue-medium;
      @include bp('tablet') {
        padding: 20px 0;
      }

      .edit--new--note {
        padding: 0;

        .skills {
          margin-bottom: 16px;
          flex-direction: column;
          width: 100%;

          .bc-autocomplete {
            width: 25%;
            margin-bottom: 8px;
          }

          section {
            display: flex;
            flex-direction: row;
            padding: 0;
            max-width: 100%;
            flex-wrap: wrap;

            .bc-tag {
              margin-right: 8px;
              width: max-content;
              flex: 0 0 auto;
              margin-bottom: 8px;
            }
          }
        }

        .salary {
          margin-bottom: 16px;
          width: 100%;

          p {
            margin-right: 8px;
          }

          div {
            margin-right: 16px;
          }

          input {
            padding: 8px;
          }
        }

        .additional {
          align-items: center;
          margin-bottom: 16px;
          width: 100%;

          .bc-checkbox {
            margin: 0;
          }

          p {
            margin: 0 16px;
          }

          .bc-dropdown {
            width: 16%;

            .bc-dropdown__input {
              button {
                border-color: #3c80f74d;
                color: #3c80f7;
              }
            }
          }
        }
      }
    }

    &__editor-wrapper {
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__button {
      height: 25px;
      width: 25px;
      color: $color-white;

      &--red {
        background: $color-error;
      }

      &--blue {
        background: $color-primary;
      }
    }

    &__modal {
      padding: 30px;
      color: $color-secondary;
    }

    &__modal-wrapper {
      margin-top: 20px;
    }

    &__modal-title {
      margin-top: 0;
    }

    &__modal-button {
      margin: 0 10px;
    }

    &__title {
      color: $color-tertiary;
      margin: 0;
    }

    &__types {
      margin-top: 15px;
      background: rgba($color-primary, 0.1);
    }

    &__type {
      padding: 5px;
      color: $color-primary;
      border: 1px solid transparent;
      @include bp('tablet') {
        padding: 10px 20px;
      }

      &:hover {
        border: 1px solid $color-primary;
      }

      &--active {
        border: 1px solid $color-primary;
        background: $color-white;
      }
    }

    &__checkboxes {
      margin: 0.5em -1.5em;
    }

    &__checkbox {
      margin: 0.5em 1.5em;
    }

    &__label {
      cursor: pointer;

      &:before {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
</style>
